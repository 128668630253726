@import '../../../../styles/variables.scss';

.root {
  background-color: #f8f8fa;
  color: $almost-black;
  height: 176px;
  height: 100%;
  padding: 40px $spacing-triple;
  box-shadow: inset 0 -1px 3px 0 rgba(92, 80, 80, 0.1),
    inset 0 -2px 2px -2px rgba(0, 0, 0, 0.06),
    inset 0 -2px 2px 0 rgba(0, 0, 0, 0.07);

  @include for-tablet-landscape-up {
    padding: 40px 48px;
  }

  display: flex;

  h5,
  p {
    font-size: 0.875rem;
    line-height: 20px;
  }

  .clientName {
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    h1 {
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.5rem;
    }
    @include for-tablet-portrait-down {
      flex-direction: row;
      justify-content: flex-start;

      h1 {
        margin-right: 8px;
      }
    }
  }

  .clientStatus,
  .contactInfo {
    display: flex;
    flex-direction: column;
    height: 100%;

    h5 {
      font-weight: 600;
    }

    p {
      display: flex;
    }

    @include for-tablet-portrait-down {
      padding-top: 16px;

      p {
        margin-top: 4px;
      }
    }
  }

  .clientStatus {
    flex-grow: 3;
    align-items: flex-start;
  }

  .contactInfo {
    flex-grow: 4;
  }

  .questionnaireLink {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    align-items: flex-start;
    justify-content: flex-start;

    h5 {
      padding-bottom: 16px;
    }
  }
}

.editCoach {
  background: transparent;
  cursor: pointer;
  margin: 0 0 0 0.325rem;
  padding: 0;

  i {
    font-size: 1rem;
    vertical-align: bottom;
  }
}

.modal {
  background-color: rgba(11, 79, 108, 0.88);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 99999;
}

.modalContainer {
  background-color: $white;
  border-radius: 4px;
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05),
    -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06),
    -2px -2px 2px 0 rgba(0, 0, 0, 0.07);
  display: inline-block;
  margin-top: 10vh;
  max-width: 44rem;
  padding: 0 48px 32px;
  text-align: left;

  > header {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    margin: 0 -48px 36px;
    padding: 24px 48px;

    h2 {
      font-size: 30px;
      font-weight: 100;
    }
  }
  > div {
    h4 {
      color: $turquoise;
      font-size: 21px;
      font-weight: 300;
      margin-bottom: 24px;
    }

    select {
      width: 100%;
    }
    > p {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    label {
      display: block;
      font-size: 1rem;
      margin-bottom: 0.5rem;

      input {
        margin-right: 0.325rem;
      }
    }
  }

  > footer {
    margin-top: 4rem;
    text-align: right;

    button {
      background-color: $turquoise;
      border-radius: 4px;
      color: $white;
      font-size: 18px;
      padding: 12px 48px;

      &:disabled {
        cursor: default;
        opacity: 0.6;
      }
    }
  }
  button {
    cursor: pointer;
  }
}

.closeModal {
  // !important, as this is set globally in `src/styles/lists.scss`
  font-size: 36px !important;
  font-weight: 100 !important;
  height: auto !important;
  position: relative !important;
  right: auto !important;
}

.messageClientContainer {
  display: flex;
  flex-direction: column;
}

.messageClient {
  background-color: $turquoise;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  margin: 0.75rem auto 0.75rem 0;
  padding: 0.75rem 1rem;
  width: auto;
}

@value forPhoneOnly from '~styles/media.sss'

.AddTimeZone
  padding: 0 1em
  margin: 2em auto
  max-width: 400px

.FormTitle
  font-family: 'Open Sans', sans-serif
  font-weight: bold
  font-size: 16px
  margin: 1em 0

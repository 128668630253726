@value white01, turquoise01, grey01, errorRed from '~styles/colors.sss'

.modal
  background-color: rgba(11, 79, 108, 0.88)
  bottom: 0
  left: 0
  position: fixed
  right: 0
  text-align: center
  top: 0
  z-index: 100

.modalContainer
  background-color: white01
  border-radius: 4px
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05),
    -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06),
    -2px -2px 2px 0 rgba(0, 0, 0, 0.07)
  display: inline-block
  margin-top: 10vh
  max-width: 40rem
  padding: 2rem 3rem
  text-align: left

.textarea
  border-radius: 3px
  border: 2px solid grey01
  height: 10.5rem
  font-size: 1rem
  margin: 0.5rem
  outline: none
  padding: 0 0.75rem
  width: 30rem

.ErrorText
  color: errorRed

@value black01, turquoise01, white01, lightGrey, errorRed from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.Header
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), -2px 1px 5px 0 rgba(0, 0, 0, 0.1), -2px 3px 1px -2px rgba(0, 0, 0, 0.06), -2px 2px 2px 0 rgba(0, 0, 0, 0.07)
  border: 0.5px solid rgba(0, 0, 0, 0.1)
  border-radius: 4px
  display: flex
  align-items: center
  height: 48px
  letter-spacing: 1px
  background-color: white01
  position: relative
  z-index: 4

.Title
  display: flex
  color: turquoise01
  font-size: 20px
  font-weight: 600
  max-width: 900px

.Separator
  border-top: 1px solid lightGrey
  margin-top: 24px
  padding-top: 24px

.ConsentError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed
  color: errorRed

.Content
  padding-top: 2em
  display: flex
  flex-direction: column
  max-width: 900px
  margin: auto

.Content > div
  padding: 0 1em

.Consent
  padding: 0 1em
  display: flex
  align-items: center
  margin: 1em 0

.Consent label
  margin-left: 1em
  cursor: pointer

.Documents
  padding: 0 1em
  margin: 4em 0

.Documents h4
  color: turquoise01

.DocumentsList
  padding: 0 1em
  list-style-type: none
  padding-left: 1em

.DocumentsList li
  margin: 1em 0

.DocumentsList li a
  color: black01

.DocumentsList li a:hover
  color: turquoise01

.Actions
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05), -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06), -2px -2px 2px 0 rgba(0, 0, 0, 0.07)
  background: white01
  display: flex
  flex-direction: column
  padding: 1em
  width: 100%

.Action
  width: 100%
  margin: 0.2em 0

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .Header
    padding: 0 48px

  .Title
    padding: 0 1em

  .Content
    padding: 1em 0
    margin-bottom: 80px

  .Actions
    flex-direction: row
    justify-content: space-between
    position: fixed
    bottom: 0
    left: 0

  .Action
    width: auto
    margin: 0 1em

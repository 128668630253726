@value black01, blue01, blue02 from '~styles/colors.sss'

// ThreadList

.ThreadList
  overflow: auto
  display: flex
  flex-direction: column
  flex: 1

.LoadingNextThreads
  display: flex
  background-color: blue02
  padding: 1em
  margin: 0.5em 0
  user-select: none
  pointer-events: none

// Thread

.Avatar
  margin-right: 0.5em

.Recipient
  font-size: 14px
  color: blue01
  font-weight: 600
  margin: 0
  position: relative
  bottom: 5px

.Body
  color: black01
  font-size: 0.75rem
  line-height: 1.125rem
  text-overflow: ellipsis
  white-space: nowrap
  overflow-x: hidden
  max-width: 265px
  margin: 1.5em 0

.You
  color: blue01
  font-size: 14px
  font-weight: 400
  margin-top: 1em

@import '~styles/variables';

.calendarContainer {
  flex: 0 0 364px;
	border-left: 1px solid $border;
	// margin-left: 16px;
	// padding-left: 16px;
  position: relative;
  z-index: 1;
	overflow: hidden;
	grid-column: 3;
}

.calendarContainer > header {
	color: $almost-black;
	font-size: 1.5rem;
	padding: 16px 0 16px 16px;
	box-shadow: 0 1px 5px 0 rgba(0,0,0,0.10), 0 3px 1px -2px rgba(0,0,0,0.06), 0 2px 2px 0 rgba(0,0,0,0.07);
	display: flex;
	align-items: center;

  .openCalendar {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
		flex-direction: row;
		cursor: pointer;
    img {
      padding: $spacing-standard $spacing-triple;
      height: 16px;
      width: 16px;
    }
  }
}

.calendarControls {
	display: flex;
	justify-content: space-between;
	width: 36px;
	margin-right: 24px;

	i {
		width: 14px;
		height: 14px;
		border-bottom: 2px solid $orange;
		border-left: 2px solid $orange;
		cursor: pointer;
	}

	i:first-of-type {
		transform: rotate(45deg);
	}

	i:last-of-type {
		transform: rotate(-135deg)
	}
}

.calendarScrollContainer {
	height: calc(100vh - 204px);
	padding: 24px 24px 48px 8px;
	position: relative;
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}
}

.calendarCellContainer {
	height: 100%;
	position: relative;
}

.calendarCurrentTimeIndicator {
	background-color: $orange;
	position: absolute;
	left: 54px;
	right: 0;
	height: 2px;
	z-index: 2;
}

.calendarCell {
	display: flex;
}

.calendarCell > span {
	font-size: 0.7em;
	color: $dark-grey;
	text-align: center;
	flex: 0 0 54px;
	position: relative;
	top: -4px;
}

.calendarCell > div {
	flex: 0 0;
	flex-basis: calc(100% - 54px);
	border-top: 1px solid $border;
}

.calendarAppointmentsContainer {
	position: absolute;
	top: 24px;
	bottom: 48px;
	left: 62px;
	right: 8px;
}

.calendarAppointment {
  background-color: rgba(46,196,182,0.5);
	position: absolute;
	left: 0;
	right: 0;
	min-height: 20px;
	border-radius: 3px;
	padding: 4px 8px;
	font-size: 0.75em;
	color: $almost-black;

	display: flex;
	align-items: center;

	&:hover {
		z-index: 3;
	}

	b {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 50%;
    font-weight: 600;
	}

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 100%;
		margin-left: 8px;
	}

	i {
		display: inline-block;
		margin: 0 3px;
		font-style: normal;
	}

}

.calendarAppointmentExpanded {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	padding: 8px;

	b {
		max-width: 100%;
	}

	span {
		margin-left: 0;
		margin-top: 4px;
	}
}

.calendarAppointmentPast {
  background-color: rgba(46,196,182,0.1);
	color: #999;

	u {
		text-decoration: none;
	}
}

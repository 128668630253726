@value white01, lightTurquoise from '~styles/colors.sss'

.Rating
  display: flex
  flex-direction: column

.Rating ul
  display: flex
  justify-content: space-around
  background-color: lightTurquoise
  padding: 1em 0

.Rate
  align-items: center
  display: flex
  flex-direction: column
  font-size: 12px

.Rate input
  display: none

.InputError
  border-color: red
  box-shadow: 0 0 4px red

.ErrorText
  color: red

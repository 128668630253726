.SingleMatrix
  display: flex

.Row
  display: flex
  flex-direction: column

.Row > label
  font-size: 14px
  margin: 0.5em 0

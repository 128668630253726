@value forTabletPortraitUp from '~styles/media.sss'

.MessageTypeButton
  font-size: 14px
  border-radius: 0
  min-height: 35px
  line-height: normal
  opacity: 0.5

.Selected
  opacity: 1

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .MessageTypeButton
    line-height: 34px
    margin: 0
    border-radius: 0
    font-size: 18px

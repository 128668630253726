@import '~styles/variables';
@import '../../styles/mixins';

.root {
  background-color: $white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.06),
    0 2px 2px 0 rgba(0, 0, 0, 0.07);
  position: relative;
  height: $navbarHeight;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: $spacing-triple;
  padding-right: $spacing-double;
  @include for-tablet-landscape-up {
    padding-left: 48px;
  }
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;

  &.drawerOpen {
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
  }
}

.navLinksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: $almost-black;
  cursor: pointer;
  font-size: 0.8125rem;
  letter-spacing: 1px;
  font-weight: 600;
}

.navLinkListWrapper {
  height: 64px;
  min-width: 96px;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px 0 $spacing-triple;
  font-size: 1rem;
  font-weight: 400;
  box-sizing: border-box;

  @include mediaQuery(max, 600px) {
    display: none;
  }

  span {
    display: block;
    position: relative;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $almost-black;
      position: absolute;
      right: -18px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  ul {
    list-style-type: none;
    position: absolute;
    top: 56px;
    right: 0;
    width: 132px;
    background-color: #fff;
    padding: 18px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    transition: transform 0.15s $curve;
    transform-origin: 0 0;
    transform: scaleY(0);
  }

  &:hover ul {
    transform: scaleY(1);
  }

  li {
    color: inherit;
    font-size: 90%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  li + li {
    margin-top: 18px;
  }
}

.navLinkWrapper {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $spacing-triple;
  text-decoration: none;
  color: inherit;
  .navLink {
    min-width: 64px;
    font-size: 1rem;
    font-weight: 400;

    &.treatmentPlanLink {
      min-width: 150px;
      text-align: center;
    }
  }
  @include for-phone-only {
    display: none;
  }
}

.mobileMenu {
  @include for-phone-only {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
  }
  @include for-tablet-portrait-up {
    display: none;
  }
  color: $almost-black;
  .menuButton {
    font-size: 2.25rem;
    position: relative;
    top: 1px;
    left: 2px;
    padding: 0 $spacing-double 0 $spacing-standard;
  }
}
@include for-phone-only {
  .desktopLogo {
    display: none;
  }
}
@include for-tablet-portrait-up {
  .mobileLogo {
    display: none;
  }
}

.logoWrapper {
  &:hover {
    cursor: pointer;
  }
  height: 60%;
  img {
    outline: none !important;
    height: 100%;
  }
}

.navButtonActive {
  color: $turquoise;
}

.Tooltip {
  background-color: $turquoise !important;
  border: 1px solid $almost-black;
}

@import '~styles/variables';

.regimen {
  margin-top: 3rem;

  > h3 {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  > ul {
    border-bottom: 1px solid $border;
    list-style: none;
    padding-bottom: 2rem;

    @include for-tablet-landscape-up {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

/*
** Brand colors
*/
@value white01: #FFFFFF
@value orange01: #FF9F1C
@value blue01: #0B4F6C
@value blue02: #F5F5FB
@value turquoise01: #2EC4B6
@value black01: #3D3D3D
@value grey01: #B8C1CB
@value border01: #DEDEDE
@value darkGrey: #757575
@value lightGrey: #C8CDE1
@value lighterGrey: #E5E5E5
@value bluePrimary: #456990
@value blueNavButton: #224F6D
@value bluePrimaryDark: #00354c
@value blueMobileClickState: #1B3F57
@value lightTurquoise: #C6EDEB
@value lighterTurquoise: #E2F6F5

/*
** Feedback colors
*/
@value errorRed: #FF5353
@value infoBlue: #BED1D8
@value successGreen: #A8E9A8

/**
 * Card shadow
 */

@value cardShadow: 1px 1px 3px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(0,0,0,0.05)

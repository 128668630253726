@value turquoise01, orange01, black01 from '~styles/colors.sss'

.UpcomingCheckInTable
  display: flex
  flex-direction: column

.TableHeader
  display: flex
  justify-content: space-between

.Title
  display: flex
  align-items: center

.Title h1
  color: turquoise01
  font-size: 20px
  font-weight: 400
  margin-left: 1em

.ResultsCount
  margin-top: 1em
  display: flex
  color: black01

@value turquoise01, grey01, black01 from '~styles/colors.sss'

.Note
  display: flex
  flex-direction: column
  margin-bottom: 1em
  position: relative

.Header
  display: flex
  justify-content: space-between
  padding: 2em 0 1em

.Author
  color: turquoise01
  font-size: 1rem
  line-height: 1.5rem

.Date
  color: grey01
  font-size: 0.875rem
  align-self: flex-end

.Body
  color: black01

.Title
  font-size: 1rem
  font-weight: 600
  line-height: 1.5rem
  margin-bottom: 6px

@value black01, errorRed from '~styles/colors.sss'

.PhoneNumberInput > input:not([disabled])
  border: 1px solid black01
  border-radius: 4px
  box-sizing: border-box
  height: 48px
  width: 100%
  padding: 0.5em 1em
  font-size: 1rem
  line-height: 1.5rem

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

.PhoneNumberInput > input:disabled
  background-color: transparent
  border: none

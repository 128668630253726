@value black01 from '~styles/colors.sss'

// TYPES

.Loader
  top: 50%
  left: 50%

.absolute
  composes: Loader
  position: absolute

.fixed
  composes: Loader
  position: fixed

// CONTENT
.Content
  display: flex
  flex-direction: column
  align-items: center
  font-family: Muli, sans-serif
  font-weight: 300
  color: black01

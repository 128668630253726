@value turquoise01, orange01, black01, lighterTurquoise from '~styles/colors.sss'

.TABLE
  width: 100%
  border-collapse: collapse
  font-size: 18px
  color: black01

.THEAD
  border-bottom: 1px solid turquoise01
  color: orange01
  text-align: left

.TR
  border-bottom: 1px solid turquoise01

.TBODY .TR
  font-size: 16px

.TBODY .TR:hover
  background-color: lighterTurquoise

.TH
  height: 60px
  line-height: 35px
  padding: 1em 0

.TD
  height: 45px
  line-height: 45px
  padding: 1em 1em

@value forTabletPortraitDown from '~styles/media.sss'

.SuccessStory
  display: flex
  align-items: center
  justify-content: space-around
  flex-wrap: wrap

.SuccessStory h1
  font-size: 38px
  font-style: italic
  text-align: center

.SuccessStory p
  font-size: 18px
  margin: 1em 0
  font-style: italic
  text-align: center

.SuccessStory img
  max-width: 450px
  display: block
  margin: 0 auto

.SuccessStory div
  width: calc((100% / 2) - 15px)

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitDown
  .SuccessStory
    flex-direction: column

  .SuccessStory img
    width: 100%

  .SuccessStory div
    width: 100%

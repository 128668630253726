@value white01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.Guarantee
  display: flex
  flex-direction: column
  align-items: center

.Guarantee > h1
  font-size: 25px
  color: white01
  font-weight: 700
  text-align: center
  margin: 0

.Box
  display: flex
  flex-direction: column
  align-items: center
  background-color: white01
  padding: 1em
  margin-top: 1em

.Box > h1
  font-size: 17px
  text-align: center

.Box > p
  font-size: 18px
  line-height: 1.5

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .Box
    max-width: 580px

@value forTabletLandscapeUp from '~styles/media.sss'

.PhotoUpload
  list-style: none
  margin: 0 -0.25rem 1rem

.ImageChoice img
  transition: opacity 0.3s ease
  transition: width 1s
  width: 100px
  height: auto

.ImageChoice img:hover
  width: 210px
  transition: width 1s

.ImageChoice .selectedChoice
  border-color: $turquoise

.ImageChoice .selectedChoice img
  opacity: 1

.ImageChoice .nonSelectedChoice img
  opacity: 0.5

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .PhotoUpload
    display: flex
    justify-content: left

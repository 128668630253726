@import '~styles/variables';

$containerMaxWidth: 1300px;
$surveyResultsNavWidth: 260px;

.root {
  color: $almost-black;
  padding: 24px 48px;

  @include for-tablet-portrait-down {
    padding: 24px;
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column-start: 2;

    @include for-tablet-portrait-down {

      h1 {
        display: flex;
        flex-direction: column;
      }
    }
    .clientName {
      color: $orange;
    }
    .clientLink {
      cursor: pointer;
    }
    a {
      text-decoration: none;
    }
  }

  &.grid {
    display: grid;
    grid-template: 160px 1fr / 48px 1fr 48px;
    padding: 0;

    @include for-tablet-portrait-down {
      grid-template: 160px 1fr / 24px 1fr 24px;
      padding: 0;
    }

    .surveyResults {
      display: grid;
      grid-column-start: 2;
      grid-template: 1fr / 1fr 3fr;

      @include for-tablet-portrait-down {
        grid-template: auto auto / 1fr;

        .surveyResultsNav {
          grid-row-start: 1;
        }
        .resultPages {
          grid-row-start: 2;
          // grid-column-start: 1;
        }
      }
    }

    .resultPages {

      @include for-tablet-landscape-up {
        margin: -24px 0 320px 24px;
        grid-column-start: 2;
      }
    }
  }
}

/**
 *  survey sections styling
 */

.pageSection {
  padding: 8px;

  h2 {
    font-size: 1.625rem;
    color: $turquoise;
    padding: 16px 0;
  }
  .questionContainer {
    margin-bottom: 1rem;
  }
  .question {
    font-weight: 600;
    padding: 4px 0;
  }
}

.answerWrapper {

  padding-bottom: 8px;

  .imageChoiceContainer {

    list-style-type: none;
    margin: 0;
    padding: 1rem;

    @include for-tablet-landscape-up {
      display: flex;
      overflow-x: auto;
    }

  }

  .imageChoice {

    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    @include for-phone-only {
      margin-bottom: 1%;
      margin-right: 0;
    }

    &.activeChoice {
      border: 2px solid $turquoise;
      img {
        opacity: 1;
      }
    }

    img {
      opacity: 0.5;
      padding: 1rem;
    }
  }

  .openEndedMulti {
    margin-block-end: 1rem !important;
    margin-block-start: 1rem !important;
    list-style-type: disc !important;
    padding-inline-start: 40px !important;
  }

  .matrixRating {
    margin: 1rem 0 0;

    ul {
      list-style-type: none;

      > li {
        background-color: rgba(0,0,0,0.05);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        padding: 0.5rem 2rem;
        width: calc(100% - 4rem);

        > label {
          cursor: text;
          flex: 1;
          text-align: center;

          input[type='radio'] {
            display: none;
          }
          i {
            color: rgba(0,0,0,0.1);
            font-size: 2rem;

            &.filled {
              color: $turquoise;
            }
          }
          .choiceLabel {
            color: rgba(0,0,0,0.1);
            display: block;
            font-size: 0.75rem;

            &.filled {
              color: $dark-grey;
            }
          }
        }
      }
    }
  }
}

.choiceWrapper {
  padding: 2px 0;
}

/**
 *  quicklinks styling
 */

.surveyResultsNav {
  flex: 0 0 $surveyResultsNavWidth;
  max-width: $surveyResultsNavWidth;
  min-height: 200px;
  padding: 0 18px 0 0;

  header {
    font-weight: 600;
    font-size: 0.875rem;
    margin: 4px;
  }

  ul {
    list-style-type: none;
    color: $orange;
    font-weight: 600;
    overflow: hidden;
  }

  li {
    position: relative;
  }

  a {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;
    text-decoration: none;
    transition: text-shadow 0.15s ease-out;
    &:hover {
      text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
      transition: text-shadow 0.15s ease-out;
    }
  }

  @include for-tablet-portrait-down {
    margin-bottom: 32px;
    max-width: none;
    width: 100%;
  }
}

.stickyNav {
  @include for-tablet-landscape-up {
    position: fixed;
    margin-top: -160px;
  }
}

// nav item icon container
.surveyResultsNav a > div {
  margin-right: 12px;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $orange;
  fill: $orange;

  svg {
    height: 16px;
    width: auto;
  }
}

// nav item text
.surveyResultsNav a > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * followup question styling
 */

.followupQ {
  margin: 0px 24px 16px;
}

// Photo upload styling
.fileUpload {

  ul {
    list-style-type: none;
    margin: 1rem -0.25rem;

    @include for-tablet-landscape-up {
      display: flex;
      justify-content: space-around;
    }

    > li {
      cursor: pointer;
      margin: 0 0.25rem 2rem;
      text-align: center;

      @include for-tablet-landscape-up {
        margin-bottom: 0;
        width: calc(100% / 3);
      }
      > span {
        display: block;
        font-size: 0.875rem;
        margin-top: 0.25rem;
      }
      img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        width: auto;
      }
    }
  }
}

// Checkin specific styling
.root.checkin {

  .questionContainer {
    border-top: 1px solid $border;
    margin: 0;
    padding: 0.5rem 1rem;

    &:last-of-type {
      border-bottom: 1px solid $border;
    }
    &:nth-of-type(odd) {
      background-color: rgba(0,0,0,0.02);

      .question {
        font-weight: 900;
      }
    }
    &.columns {

      .answerWrapper {
        padding: 4px 0;
      }
      > div {
        display: flex;
        justify-content: space-between;

        > div {
          width: 50%;

          &:last-of-type {
            text-align: right;
          }
        }
      }
    }
  }
}

.existingCheckinsNav {
  margin: 2rem 0;

  h3 {
    margin-bottom: 1rem;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin-left: -0.5rem;
    width: calc(100% + 1rem);
  }
  li {

    button {
      background-color: $white;
      border: 2px solid $turquoise;
      border-radius: 4px;
      color: $turquoise;
      cursor: pointer;
      margin: 0.5rem;
      padding: 0.5rem 0.75rem;

      &.active {
        background-color: $turquoise;
        border: none;
        color: $white;
      }
      h4 {
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 0.875rem;
        font-style: italic;
      }
    }
  }
}

.existingCheckinLoading {
  position: fixed;
  margin: auto 0;
}

.otherImagesLoading {
  margin: 1rem auto;
  text-align: center;
}

.otherPhotos {
  margin: 2rem 0 1rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: end !important;
    max-width: 100%;

    li {
      width: auto !important;

      button {
        background-color: $white;
        border: 2px solid $turquoise;
        border-radius: 4px;
        color: $turquoise;
        cursor: pointer;
        margin: 0.5rem;
        padding: 0.5rem 0.75rem;

        h4 {
          margin-bottom: 0.5rem;
        }
        p {
          font-size: 0.875rem;
          font-style: italic;
        }
      }

      .SelectedButton {
        background-color: $turquoise;
        border: none;
        color: $white;
      }

    }
  }
}

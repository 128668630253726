@value white01, turquoise01 from '~styles/colors.sss'

.ConfirmPlanModal
  display: flex
  position: relative
  flex-direction: column
  align-items: center

.ConfirmPlanModal p
  font-size: 20px
  margin: 1em 0

.ConfirmPlanModal footer
  display: flex
  margin-top: 2em

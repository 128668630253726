@import '~styles/variables';

$contentMaxWidth: 900px;

.header {
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.05), -2px 1px 5px 0 rgba(0,0,0,0.10), -2px 3px 1px -2px rgba(0,0,0,0.06), -2px 2px 2px 0 rgba(0,0,0,0.07);
  border: .5px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: $turquoise;
  font-size: 20px;
  font-weight: bold;
  height: 48px;
  letter-spacing: 1px;
  margin-left: 1px !important;
  margin-right: 1px !important;
  padding: 0 16px;

  @include for-tablet-portrait-up {
    padding: 0 48px;
  }

  &:hover {
    cursor: pointer;
  }

  // overlap container inset box-shadow element under header
  background-color: #FFF;
  position: relative;
  z-index: 4;

  & > div {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: $contentMaxWidth;
  }
}

.headerCoach {
  color: $turquoise;
  font-size: 20px;
  font-weight: bold;
  height: 48px;
  letter-spacing: 1px;
  padding: 0 16px;

  @include for-tablet-portrait-up {
    padding: 0 48px;
  }

  & > div {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: $contentMaxWidth;
  }
}

.CheckboxLabel{
  display: flex;
  position: relative;
  bottom: 30px;
  justify-content: flex-start;
  margin-left: 4em;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.875rem;
  color: $dark-grey;
  font-weight: 600;
  height: 35px;
}

.documentsHeader {
  color:$turquoise;
  font-weight: bold;
  margin-top: 70px;
  margin-bottom: 10px;
}

.documentLinks {
  list-style-type: none;
  padding-left: 10px;
  padding: 8px;
  margin-top: 20px;
    li{
      margin-top: 20px;
    }
    a{
      color: #808080;
    }
    a:hover{
      color: $turquoise;
    }
}

.body {
  padding: 24px 16px;

  @include for-tablet-portrait-up {
    max-height: calc(100vh - 198px);
    overflow-y: auto;
    padding: 24px 48px;
  }

  & > div {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 auto;
    max-width: ($contentMaxWidth - 16px);
    white-space: pre-line;
  }
  .consent {
    border-top: 1px solid $light-grey;
    margin-top: 24px;
    padding-top: 24px;
  }
}

.footer {
  box-shadow: inset 0 -1px 2px rgba(0,0,0,0.05), -2px -1px 5px 0 rgba(0,0,0,0.10), -2px -3px 1px -2px rgba(0,0,0,0.06), -2px -2px 2px 0 rgba(0,0,0,0.07);
  padding: 16px;

  @include for-tablet-portrait-up {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    padding: 16px 48px;

    > div {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: $contentMaxWidth;
    }
  }

  button {
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 16px;
    padding: 12px 28px;
    width: 100%;

    @include for-tablet-portrait-up {
      width: auto;
      margin-bottom: 0;
    }

    &:disabled {
      cursor: text;
      opacity: 0.5;
    }
  }

  .buttonMoreQuestions,
  .buttonReject {
    background-color: transparent;
    border: 2px solid $orange;
    color: $orange;

    @include for-tablet-portrait-up {
      margin-right: 24px;
    }
  }

  .buttonAccept {
    background-color: $turquoise;
    color: $white;
  }
}

.modal {
  background-color: rgba(11, 79, 108, 0.88);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 99999;
  overflow-x: scroll;
}

.modalContainer {
  background-color: $white;
  box-shadow: inset 0 -1px 2px rgba(0,0,0,0.05), -2px -1px 5px 0 rgba(0,0,0,0.10), -2px -3px 1px -2px rgba(0,0,0,0.06), -2px -2px 2px 0 rgba(0,0,0,0.07);
  display: block;
  height: 100%;
  padding: 24px 16px 32px;
  position: relative;
  text-align: left;

  @include for-tablet-portrait-up {
    border-radius: 4px;
    display: inline-block;
    height: auto;
    margin-top: 10vh;
    max-width: calc(100% - 32px);
    padding: 0 48px 32px;
    width: 44rem;
  }

  > header {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    margin: 0 -48px 36px;
    padding: 24px 16px;

    @include for-tablet-portrait-up {
      padding: 24px 48px;
    }

    h2 {
      font-size: 30px;
      font-weight: 100;
    }
  }
  > div {

    h4 {
      color: $turquoise;
      font-size: 21px;
      font-weight: 300;
      margin-bottom: 24px;

      @include for-tablet-portrait-up {
        margin: 36px 0 24px;
      }
    }
    .wysiwygContainer {
      margin-top: 24px;
      width: calc(100% - 1rem);
    }
  }
  > footer {
    margin-top: 4rem;
    text-align: center;

    @include for-tablet-portrait-up {
      display: flex;
      justify-content: space-around;
    }

    button {
      background-color: $turquoise;
      border-radius: 4px;
      color: $white;
      font-size: 18px;
      margin-bottom: 16px;
      padding: 12px 48px;
      width: 100%;

      @include for-tablet-portrait-up {
        margin-bottom: 0;
        width: auto;
      }

      &:disabled {
        cursor: text;
        opacity: 0.5;
      }
      &.modalCancelBtn {
        background-color: transparent;
        color: $orange;
        height: 40px;
        padding: 0;

        @include for-tablet-portrait-up {
          bottom: -60px;
          left: calc(50% - 50px);
          position: absolute;
          width: 100px;
        }
      }

      &.modalPreviousBtn {
        background-color: transparent;
        border: 2px solid $orange;
        color: $orange;
      }
    }
  }
  button {
    cursor: pointer;
  }
}

.confirmModal {
  h4 {
    color: $turquoise;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;

    @include for-tablet-portrait-up {
      margin: 36px 0 24px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 600;
    margin-top: 1em;
  }
  ul{
    margin-left: 40px;
  }
}

        // 24 18 fontweight 600 margin a areas
.closeModal {
  // !important, as this is set globally in `src/styles/lists.scss`
  font-size: 36px !important;
  font-weight: 100 !important;
  height: auto !important;
  position: relative !important;
  right: auto !important;
}

.rejectionReason,
.communicationMethod,
.sunscreenSelection,
.treatmentArea {
  margin-top: 8px;

  input {
    margin-right: 6px;
  }
}

.Rejection {
  margin-top: 4em
}

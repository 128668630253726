@value lightGrey from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.Compose
  display: flex
  flex-direction: column
  flex-shrink: 0

.MessageTypeIcon
  height: 16px
  width: 16px
  padding: 0 1em
  margin: 0

.TextArea
  margin-top: 0.5em

.MessageTypes
  display: flex
  flex-direction: column

.MessagesNumber
  display: flex
  align-items: center
  justify-content: center
  margin-right: 0.5em
  background-color: lightGrey
  border-radius: 25%
  width: 22px
  height: 22px

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .MessageTypes
    display: flex
    flex-direction: row

  .MessageTypeIcon
    position: relative
    top: 5px
    right: 5px

@value white01, errorRed, lightTurquoise, lighterTurquoise from '~styles/colors.sss'

.Rank
  display: flex
  margin: 0.5em 0
  background-color: lightTurquoise
  padding: 1em

.RankDragging
  background-color: lighterTurquoise

.Rank .Index
  margin-left: 0.5em
  font-weight: 700

.Rank .Text
  margin-left: 1em

.NAChoice
  display: flex
  align-items: center

.NAChoice label
  margin-left: 1em

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

@value black01 from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'
@value contentMaxWidth: 900px

.ConversationPage
  display: flex
  flex-direction: column
  height: calc(100vh - 64px)
  padding-top: 1em
  margin: 0 auto

.Thread
  display: flex
  flex: 1
  padding: 12px 24px 0 24px
  width: 100%

.Thread::-webkit-scrollbar
  display: none

.EmptyMessageList
  margin-top: 24px
  font-size: 1.2em

.EmptyMessageList span
  color: black01

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .ConversationPage
    height: calc(100vh - 120px)
    max-width: contentMaxWidth
    padding-top: 2em

@value blue01, bluePrimary, turquoise01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.Navbar
  display: flex
  flex-direction: column
  align-items: center
  background-color: blue01

.Navbar ul
  display: flex
  align-items: center
  justify-content: center
  list-style: none
  margin: 1em 0

.Navbar ul li
  border-bottom: 4px solid bluePrimary
  display: flex
  align-items: center
  justify-content: center
  width: 30px
  padding: 1em 0
  margin: 0 0.2em

.ActivePage
  border-bottom: 4px solid turquoise01 !important

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .Navbar ul li
    width: 80px
    margin: 0 0.5em

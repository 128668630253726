.Form
  display: flex
  flex-direction: column

.Title
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  margin: 1.5em 0
  text-align: center

.Description
  margin: 1em 0
  text-align: center

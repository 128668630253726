.Confirmation
  display: flex
  flex-direction: column
  position: relative

.Title
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  margin: 1.5em 0
  text-align: center

.Selection
  margin: 1em 0

.Description
  margin: 1em 0
  text-align: center

.Actions
  display: flex
  justify-content: center
  margin-top: 1em

@value white01, turquoise01 from '~styles/colors.sss'

.Footer
  max-width: 1190px
  padding: 0 25px
  display: block
  margin: 0 auto

.Footer > h1
  font-size: 32px
  font-weight: 700
  text-align: center
  padding: 1em
  border-bottom: 1px solid turquoise01
  margin: 0

.Footer > h1 > span
  color: turquoise01
  font-weight: 700
  text-align: center
  margin: 0

.Button
  margin: 2em 0

.SocialMedia
  padding: 0
  list-style-type: none
  display: flex
  justify-content: space-around
  max-width: 170px
  margin: 0 auto

.Links
  padding: 0
  list-style-type: none
  display: flex
  justify-content: center
  margin-top: 60px

.Links li a
  color: white01
  font-weight: bold
  font-size: 12px
  letter-spacing: 3px
  text-decoration: none

.Links li:first-child::after
  content: '|'
  color: white01
  margin-right: 13px
  margin-left: 6px
  font-weight: bold

.Footer img
  max-width: 40px
  margin: 1em 0

.Copyright
  margin-top: 30px
  font-size: 14px
  line-height: 25.2px

.PlanExtraDefinition
  font-style: oblique
  margin-top: 90px
  font-size: 12px

.Disclaimer
  margin-top: 35px
  font-size: 12px
  line-height: 25.2px
  font-style: oblique

@import 'variables';


/**
 * List page outer container
 */

:global(.list-page-container) {
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 32px 64px 32px;

  h1 {
    padding: 54px 0;
  }
}


/**
 * List page inner two column container
 */

$sidebarWidth: 256px;
$sidebarGutter: 80px;

:global(.list-page-columns) {
  display: flex;
}

:global(.list-page-sidebar) {
  flex: 0 0;
  flex-basis: $sidebarWidth;
}

:global(.list-page-content) {
  position: relative;
  flex: 1 0 auto;
  margin-left: 80px;
  max-width: calc(100% - #{$sidebarWidth + $sidebarGutter});
}

/**
 * Search input
 */

:global(.search-input-container) {
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid $grey;
  font-size: 0.9em;
  color: $almost-black;

  input {
    outline: none;
    border: none;
    padding: 0 32px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  input::placeholder {
    color: $dark-grey;
  }
}

:global(.search-icon) {
  height: 18px;
  font-size: 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  margin: auto;
  cursor: pointer;
}

:global(.clear-icon) {
  height: 14px;
  font-size: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto;
  cursor: pointer;
}

@import '~styles/variables';

$containerMaxWidth: 1300px;
$surveyOverviewNavWidth: 260px;

.surveyOverviewContainer {
  margin: 56px auto;
  max-width: $containerMaxWidth;
  padding: 0 56px;
  transition: opacity 0.15s ease-out;
}

.surveyOverviewContainer > h1 {
  font-size: 2rem;
  color: $almost-black;
  font-weight: 300;
  margin-bottom: 48px;
}

.surveyOverviewContentContainer {
  display: flex;

  @include for-tablet-portrait-down {
    display: block;
  }
}


/**
 * Nav
 */

.surveyOverviewNav {
  flex: 0 0 $surveyOverviewNavWidth;
  max-width: $surveyOverviewNavWidth;
  min-height: 200px;
  padding: 0 18px 0 0;

  ul {
    list-style-type: none;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.07), 0 2px 2px 0 rgba(0,0,0,0.06), 0 1px 3px 0 rgba(0,0,0,0.10);
  }

  li {
    position: relative;
  }

  li + li {
    border-top: 1px solid $border;
  }

  a {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 0.875rem;
    text-decoration: none;
    transition: fill 0.15s ease-out, color 0.15s ease-out;

    span {
      margin-left: 1em;
    }
  }

  @include for-tablet-portrait-down {
    margin-bottom: 32px;
    max-width: none;
    width: 100%;
  }
}

// nav item icon container
.surveyOverviewNav a > div {
  margin-right: 12px;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 16px;
    width: auto;
  }
}

// nav item text
.surveyOverviewNav a > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/**
 * Survey overview content
 */

.surveyOverviewContent {
  flex: 1 0 auto;
  max-width: calc(100% - #{$surveyOverviewNavWidth});
  min-height: 200px;
  padding: 0 18px;

  @include for-tablet-portrait-down {
    max-width: none;
  }
}

.surveyOverviewPageTitle {
  color: $turquoise;
  font-size: 1.25rem;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.surveyOverviewQuestion {
  margin-bottom: 12px;
}

.surveyOverviewQuestionChoices {
  list-style-type: none;

  li {
    color: $almost-black;
  }

  li + li {
    margin-top: 6px;
  }

  & + p {
    margin-top: 18px;
  }

  li > p {
    margin-left: 32px;
    margin-top: 6px;

    &:before {
      content: '- ';
    }
  }

  li > ul {
    margin-left: 64px;

    li:before {
      content: '- ';
    }
  }
}

@import '~styles/variables';
@import '../../../../styles/mixins';

.root {
  // display: flex;
  display: grid;
  grid-template: 1fr / 8fr 1fr 3fr;
  padding: 48px;
  header {
    padding-bottom: 16px;
  }
  @include for-tablet-portrait-down {
    padding: $spacing-triple;
    grid-template: auto auto / 1fr;
    grid-row-gap: 24px;
    header {
      padding-top: 16px;
    }
  }
}

.tabsWrapper {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  li {
    padding: 16px;
    cursor: pointer;
    position: relative;
    transition: color 0.2s ease-out;

    &::after {
      content: '';
      height: 1px;
      background-color: $turquoise;
      width: 100%;
      position: absolute;
      bottom: 8px;
      left: 0;
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }
  }
  .currentTab {
    color: $turquoise;
    transition: color 0.2s ease-out;
    &::after {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }
}

.sidebarContainer {
  grid-column-start: 3;
  h3 {
    color: $turquoise;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 8px 0;
  }
  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  @include for-tablet-portrait-down {
    grid-column-start: 1;
    grid-row-start: 1;
    line-height: 1.5rem;
    h3 {
      padding-top: 8px;
    }
  }
}

.clientActions {

  ul {
    list-style: none;

    .clientAction {
      align-items: center;
      border-radius: 4px;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      font-size: 0.875rem;
      justify-content: space-between;
      line-height: 1.125rem;
      margin: $spacing-standard 1px;
      padding: 12px;
      transition: box-shadow 0.5s ease-out;

      .clientActionCompleted {
        font-size: 0.75rem;
      }

      .clientActionButton {

        button {
          background-color: $turquoise;
          border-radius: 4px;
          color: $white;
          cursor: pointer;
          padding: 12px 28px;
        }
      }
    }
  }
}

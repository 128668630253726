@value black01, errorRed, darkGrey from '~styles/colors.sss'

.Select
  display: flex
  flex-direction: column

.Select > select
  position: relative
  border: 1px solid black01
  border-radius: 4px
  box-sizing: border-box

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

@value forTabletPortraitUp from '~styles/media.sss'

.PhotoViewer
  margin: 1em 0
  padding: 1em 0

.Photos
  display: flex
  list-style-type: none
  flex-direction: column
  justify-content: center
  align-items: center

.Photo
  display: flex
  flex-direction: column
  width: calc(100% / 3)
  align-items: center

.PhotoImage
  height: auto
  width: auto
  max-height: 100%
  max-width: 100%

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .Photos
    flex-direction: row

  .PhotoTitle
    font-size: 22px

@import '~styles/variables';

.ClientOrderProducts {
  > div {
    h4 {
      color: $turquoise;
      font-size: 21px;
      font-weight: 300;
      margin-bottom: 24px;
    }
  }
  > footer {
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05),
      -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06),
      -2px -2px 2px 0 rgba(0, 0, 0, 0.07);
    background-color: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 3rem;
    text-align: right;
    z-index: 100;

    .NextAppointment {
      display: inline-block;
    }

    .Action {
      margin-left: 2rem;
      padding: 1rem 3rem;
    }
  }
}

.productsContainer {
  padding-bottom: 6rem;
}

.categoriesList {
  list-style: none;
  margin: 2rem 0;

  > li {
    border-bottom: 1px solid $border;
    margin: 2rem 0;
    padding-bottom: 2rem;
  }
}

.productGrid {
  margin-top: 1rem;
  width: 100%;

  .ClientOrderProduct {
    display: flex;
    justify-content: space-between;

    &:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.03);
    }

    > span {
      font-size: 15px;
      padding: 0.325rem 0;

      &:nth-of-type(1) {
        max-width: calc(100% - 100px);
      }
    }
  }

  @include for-tablet-landscape-up {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .ClientOrderProduct {
      width: 50%;

      &:nth-of-type(odd) {
        background-color: transparent;
        padding-right: 1rem;
      }
      &:nth-of-type(even) {
        padding-left: 1rem;
      }
      &:nth-of-type(4n),
      &:nth-of-type(4n-1) {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}

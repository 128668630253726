@import '~styles/variables';

.surveyCompleteContainer {
  padding: 48px 0;

  @include for-phone-only {
    padding: 24px 0 0 0;
  }

  h3 {
    font-weight: 200;
    font-size: 1.5em;
    margin-bottom: 18px;
  }

  h5 {
    font-size: 1.15em;
    margin-bottom: 8px;
  }

  p {
    font-size: 1em;
    line-height: 1.4em;
  }

  button {
    display: inline-block;
    margin-right: 10px;
    margin-top: 40px;
    vertical-align: top;
  }
}

.surveyCompleteLogo {
  width: 200px;
  height: auto;
  display: block;
  margin: 0 auto 48px auto;

  @include for-phone-only {
    width: 180px;
    margin-bottom: 24px;
  }
}

.surveyCompleteModal {
  margin: 0 auto;
  max-width: 550px;
}

.surveyCompleteModal header {
  background-color: $white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  padding: 0 32px;

  span {
    color: $turquoise;
    font-size: 1.2em;
    font-weight: 400;
  }
}

.surveyCompleteModalContent {
  padding: 32px 24px;
  color: $blue-primary-dark;
  background-color: #FFF;

  @include for-phone-only {
    height: calc(100vh - 150px);
    overflow-y: auto;
  }
}

@value errorRed from '~styles/colors.sss'

.GenderSelector
  display: flex
  flex-direction: column

.Option
  display: flex
  flex: 1
  padding: 0.5em 0

.Input
  margin-right: 1em

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

@value errorRed from '~styles/colors.sss'

.Radio
  display: flex

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

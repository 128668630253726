@value black01, turquoise01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.StayClear
  display: flex
  flex-direction: column
  max-width: 1190px
  padding: 0 25px
  margin: 0 auto

.StayClear h1
  text-align: center
  line-height: 1.3
  font-size: 47px
  letter-spacing: 0
  font-weight: 500
  margin: 0 0 10px 0

.Subtitle
  line-height: 1.6
  font-size: 14px
  letter-spacing: 3px
  margin-bottom: 50px
  font-weight: 500

.Green
  color: turquoise01

.Checked
  list-style-type: none

.Checked > li
  margin-left: 50px
  font-size: 18px

.Checked > li::before
  content: ''
  background-image: url('~assets/images/checkMark.png')
  width: 35px
  height: 35px
  background-size: contain
  background-repeat: no-repeat
  display: block
  position: relative
  top: 40px
  left: -50px

.Content
  display: flex
  flex-direction: column
  text-align: left
  max-width: 900px
  margin: 0 auto
  font-size: 18px

.Image
  display: flex
  flex: 1
  flex-direction: column
  align-items: center
  justify-content: center

.Image img
  max-width: 100%

.Description
  display: flex
  flex: 1
  margin-top: 1.5em
  flex-direction: column

.Description h1
  font-size: 24px

.Description p
  color: black01
  font-size: 17px
  line-height: 2

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .Content
    flex-direction: row

@value white01, black01, turquoise01, errorRed from '~styles/colors.sss'

.Checkbox
  display: inline-block
  position: relative
  cursor: pointer

/* Hide the browser's default checkbox */
.Checkbox input[type=checkbox]
  position: absolute
  opacity: 0
  top: 0
  left: 0
  cursor: pointer
  z-index: 10

.InnerBox
  position: absolute
  background-color: turquoise01

.OuterBox
  border: 2px solid turquoise01
  position: absolute
  background-color: white01

.Checkbox input[type=checkbox] ~ .InnerBox
  opacity: 0

.Checkbox input[type=checkbox]:checked ~ .InnerBox
  opacity: 1

// SIZES

.Checkbox.medium
  height: 38px
  width: 38px

.Checkbox.small
  height: 24px
  width: 24px

.Checkbox input[type=checkbox].medium
  height: 36px
  width: 36px

.Checkbox input[type=checkbox].small
  height: 22px
  width: 22px

.InnerBox.medium
  top: 9px
  left: 9px
  height: 18px
  width: 18px

.InnerBox.small
  top: 8px
  left: 8px
  height: 11px
  width: 11px

.OuterBox.medium
  top: 0
  left: 0
  height: 32px
  width: 32px

.OuterBox.small
  top: 0
  left: 0
  height: 22px
  width: 22px

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed
  position: absolute
  left: 36px
  top: 5px

@value border01, white01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.PhotoUpload
  margin: 0 0.25rem
  list-style: none
  display: flex
  flex: 1 1 0
  flex-direction: column

.Title
  display: flex
  justify-content: center
  font-size: 0.875rem
  margin-top: 0.25rem
  text-align: center

.ImagePreview
  height: auto
  max-height: 100%
  max-width: 100%
  width: auto

.FileInput
  background-color: rgba(0, 0, 0, 0.02)
  min-height: 214px
  outline: 3px dashed border01
  outline-offset: -3px
  transition: all 0.1s ease-in

.UploadIcon
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  transition: all 0.1s ease-in

.Error
  display: block
  font-size: 0.875rem
  margin-top: 0.25rem
  text-align: center

.Preview
  position: relative

.RemovePreview
  background-color: white01 !important
  height: 1.5rem
  opacity: 0
  padding: 0
  pointer-events: none
  position: absolute
  right: 0.25rem
  top: 0.25rem
  transition: opacity 0.3s ease
  width: 1.5rem

.Preview:hover .RemovePreview
  cursor: pointer
  pointer-events: all
  opacity: 1

@value white01, turquoise01, orange01, errorRed from '~styles/colors.sss'
@value bigHeight: 50px
@value mediumHeight: 35px
@value smallHeight: 25px

.ButtonLink
  border-radius: 0.25em
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  padding: 0.5em 1.5em
  margin: 0
  user-select: none
  border: 0
  font-style: inherit
  font-family: inherit
  outline: 0
  line-height: normal
  text-decoration: none

.ButtonLink:disabled
  cursor: text
  opacity: 0.5

// types

.primary
  background-color: turquoise01
  color: white01

.sensitive
  background-color: transparent
  color: errorRed
  font-size: 0.875rem
  margin: 0.5rem 0 0
  padding: 0

.secondary
  background-color: transparent
  border: 2px solid orange01
  color: orange01

// sizes

.small
  font-size: 12px
  margin: 0.5rem 0
  min-height: smallHeight
  min-width: smallHeight

.medium
  font-size: 14px
  margin: 0.75rem 0
  min-height: mediumHeight
  min-width: mediumHeight

.big
  font-size: 18px
  margin: 0 1em
  min-height: bigHeight
  min-width: bigHeight

@value black01, errorRed, infoBlue, successGreen from '~styles/colors.sss'

.root
  margin: 16px 16px 0 16px
  padding: 16px
  border-radius: 3px

.dialogFeedback
  margin: 16px 0

.error
  background-color: errorRed
  color: black01

.info
  background-color: infoBlue
  color: black01

.success
  background-color: successGreen
  color: black01

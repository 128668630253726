@value black01, orange01, grey01, darkGrey, lightGrey, blue01, blue02, turquoise01, lighterTurquoise from '~styles/colors.sss'

.Thread
  display: flex
  flex-direction: column
  flex: 1 0
  cursor: pointer
  background-color: blue02 !important
  padding: 1em
  user-select: none

.Link
  flex: 1
  text-decoration: none

.Header
  border-color: lightGrey
  border-bottom: 1px solid grey01
  display: flex
  align-items: center
  padding: 1em 0

.Actions
  display: flex
  align-items: center
  flex: 1 0
  justify-content: flex-end
  position: relative
  bottom: 5px

.Actions > *
  margin: 0 5px

.MarkAsRead
  border-radius: 50%
  background-color: turquoise01
  width: 16px
  height: 16px

.Time
  color: orange01
  font-size: 12px

.Date
  color: lightGrey
  margin-left: 1em
  font-size: 12px

.AddressInformationForm
  display: flex
  flex-direction: column

.SectionTitle
  margin: 1.5em 0
  text-align: center

.FormTitle
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  font-size: 16px
  margin: 1em 0

.UseSameAddress
  display: flex
  margin: 3em 0

.CheckboxLabel
  position: relative
  top: 9px
  left: 36px
  margin-left: 1em

.BillingAddress
  display: flex
  flex-direction: column

.VerticalGroup
  display: flex
  flex-direction: column
  margin: 0.8em 0

.HorizontalGroup
  display: flex
  align-items: center
  margin: 0.8em 0

.InputGroup
  display: flex
  flex-direction: column
  flex: 1

.Label
  font-size: 14px
  margin: 0.5em 0

.VerticalGroup .InputGroup:not(:first-child)
  margin-top: 1em

.HorizontalGroup .InputGroup:not(:first-child)
  margin-left: 1em

.FormFooter
  text-align: center
  margin: 2em 0

@import '~styles/variables';

.clientOrderedProducts {
  p {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;

    @include for-tablet-landscape-up {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    li {
      display: flex;
      flex-basis: 100%;
      margin-bottom: 0.8rem;
    }
  }
}

@value white01, black01, turquoise01, lightTurquoise from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.Message
  display: flex

.Message ul, ol
  padding: 0 0 0 40px
  margin: 0

.Avatar
  background-color: white01
  border: 1px solid turquoise01
  margin-right: 0.8em

.MessageBody
  display: flex
  flex: 1
  flex-direction: column
  color: black01
  padding: 0 2em
  border-left: 1px solid turquoise01

.Header
  margin-bottom: 1em
  display: flex
  align-items: center

.Author
  display: flex
  font-size: 1rem

.CircleSeparator
  width: 8px
  height: 8px
  border-radius: 50%
  background-color: lightTurquoise
  margin: 0 0.8em

.Status
  display: flex
  font-size: 14px

.Status i
  font-style: normal
  color: turquoise01

.Body
  display: flex
  flex-direction: column
  color: black01
  font-size: 1rem
  line-height: 1.5rem
  white-space: pre-wrap
  overflow-wrap: break-word
  word-break: break-word

.Body > blockquote
  font-size: 18px
  padding: 1rem 2rem
  border-left: 7px solid #78c0a8
  line-height: 30px
  background: #ededed
  margin: 0

.Body > blockquote::before
  content: '\201C'
  color: #78c0a8
  font-size: 4em
  position: absolute
  left: 3rem

.MessageAttachment
  width: 340px
  height: auto

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp

  .MessageAttachment
    width: 480px

  .Body > blockquote::before
    content: '\201C'
    color: #78c0a8
    font-size: 4em
    position: absolute
    left: 0px
    top: -10px

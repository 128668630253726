@import '~styles/variables';

.qtyControl {
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid $almost-black;
  display: inline-block;
  height: 27px;

  > div {
    cursor: pointer;
    display: inline-block;
    height: 25px;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 25px;

    > span {
      bottom: 0;
      font-size: 25px;
      left: 0;
      line-height: 25px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.qtyDecrement {
      border-right: 1px solid $almost-black;

      > span {
        line-height: 20px;
      }
    }

    &.qtyIncrement {
      border-left: 1px solid $almost-black;

      > span {
        font-size: 20px;
      }
    }
  }
  > input[type='number'] {
    -moz-appearance: textfield;
    background-color: #f9f9f9;
    cursor: default;
    display: inline-block;
    font-size: 14px;
    height: 21px;
    line-height: 21px;
    padding: 0;
    pointer-events: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top;
    width: 3rem;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &.small {
    height: 18px;

    > div {
      height: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 16px;

      > span {
        font-size: 16px;
        line-height: 16px;
      }
      &.qtyIncrement > span {
        font-size: 15px;
      }
    }
    > input[type='number'] {
      font-size: 12px;
      height: 16px;
      line-height: 16px;
    }
  }
}

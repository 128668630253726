@value forTabletLandscapeUp from '~styles/media.sss'

.RegimenOrderProduct
  display: flex
  justify-content: space-between
  font-size: 15px
  padding: 0.325rem 0.25rem

.RegimenOrderProduct:nth-of-type(odd)
  background-color: rgba(0, 0, 0, 0.03)

.RegimenOrderProduct > span:nth-of-type(1)
  max-width: calc(100% - 100px)

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  /* stylelint-disable-next-line no-descending-specificity */
  .RegimenOrderProduct
    width: 50%

  .RegimenOrderProduct:nth-of-type(odd)
    background-color: transparent
    padding-right: 1rem

  .RegimenOrderProduct:nth-of-type(even)
    padding-left: 1rem

  .RegimenOrderProduct:nth-of-type(4n),
  .RegimenOrderProduct:nth-of-type(4n-1)
    background-color: rgba(0, 0, 0, 0.03)

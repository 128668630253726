@value blue01, white01 from '~styles/colors.sss'

.Avatar
  border-radius: 50%
  background-color: blue01
  display: flex
  flex-shrink: 0
  justify-content: center
  align-items: center
  width: 40px
  height: 40px

.Title
  color: white01
  font-size: 17px
  text-align: center
  margin: 0

@value blue01, turquoise01 from '~styles/colors.sss'

.root
  width: 100%
  position: fixed
  bottom: 0
  padding: 1rem
  background-color: blue01
  color: white

.footerLinks
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.link
  color: turquoise01
  text-decoration: none

.header
  font-size: 1.3rem
  text-align: center

.text
  text-align: center
  font-size: 0.8rem
  margin-top: 0.5rem

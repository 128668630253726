@value white01, turquoise01, bluePrimaryDark from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.SurveyIntro
  display: flex
  flex-direction: column

.Logo
  width: 180px
  height: auto
  display: block
  margin: 0 auto

.Modal
  background-color: white01
  display: flex
  flex-direction: column
  margin: 2em 0
  max-width: 550px
  padding: 2em
  border-radius: 5px

.ModalHeader
  display: flex

.ModalTitle
  color: turquoise01
  font-size: 1.2em
  font-weight: 400

.ModalContent
  display: flex
  flex-direction: column
  margin: 2em 0
  color: bluePrimaryDark

.ModalContent h3
  font-weight: 200
  font-size: 1.5em

.ModalContent p
  font-size: 1em
  line-height: 1.4em

.ModalFooter
  display: flex
  justify-content: flex-start

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .Logo
    width: 200px

@value turquoise01 from '~styles/colors.sss'

.NoConsultClients
  display: flex
  flex-direction: column

.Header
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 2em

.Title
  color: turquoise01
  font-size: 24px
  font-weight: 200

.Clients
  padding: 4em 2em
  max-width: 1300px
  margin: 0 auto

.ClientList
  min-height: 400px

.ClientList header > span,
.ClientList ul > li > span
  flex: 1
  text-align: right

.ClientList header > span:first-of-type,
.ClientList ul > li > span:first-of-type
  text-align: left

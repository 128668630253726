@value white01 from '~styles/colors.sss'
@value forPhoneOnly from '~styles/media.sss'

.RegimenFooter
  background-color: white01
  bottom: 0
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05), -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06), -2px -2px 2px 0 rgba(0, 0, 0, 0.07)
  display: flex
  justify-content: flex-end
  padding: 1rem 3rem
  position: fixed
  text-align: right
  width: 100%
  z-index: 10

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forPhoneOnly
  .RegimenFooter
    flex-direction: column
    padding: 1rem

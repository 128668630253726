@import '~styles/variables';

.loading {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.body {
  padding: 2.25rem 1rem;

  @include for-tablet-portrait-up {
    padding: 2.25rem 3rem;
  }
}

.personalMessage {
  margin: 2rem 0 4rem;
}

.printButton {
  margin: 1em;
}

.buttonText {
  position: relative;
  bottom: 8px;
}

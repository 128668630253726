@value white01, turquoise01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.MaintenancePlan
  display: flex
  flex-direction: column
  align-items: center
  font-family: 'Proxima Nova', sans-serif
  color: #2D2D2D

.Header
  display: flex
  padding: 15px 0 20px
  background-color: #D0D1D0
  justify-content: center
  margin: 0 auto
  width: 100%

.Header img
  max-width: 100%

.Background
  display: flex
  padding: 2em 0 1em 0
  flex-direction: column
  background-image: url('~assets/images/bg-direct-lander.jpg')
  background-attachment: fixed
  background-position: center

.Banner
  width: 100%
  display: flex
  flex-direction: column
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  padding: 4.5em 0
  text-align: center
  background-image: url('~assets/images/maintenance-banner.png')

.Highlights
  display: flex
  flex-direction: column
  padding: 3em 0

.StayClear
  display: flex
  flex-direction: column
  text-align: center
  background-image: url('~assets/images/clear-skin-success-stories-bg.png')
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  background-attachment: fixed
  padding: 70px 0
  width: 100%

.Plans
  display: flex
  flex-direction: column
  padding: 70px 0
  width: 100%
  text-align: center

.SuccessStory
  display: block
  padding: 70px 25px
  align-items: center
  max-width: 1190px
  margin: 0 auto

.Guarantee
  display: flex
  flex-direction: column
  align-items: center
  padding: 3em 1em
  background: turquoise01

.Footer
  background-color: #3D3E36
  padding: 50px 0 65px
  color: white01
  text-align: center
  width: 100%

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .Background
    width: 100%

  .Guarantee
    width: 100%

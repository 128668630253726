@value turquoise01, orange01, black01, blue01 from '~styles/colors.sss'

.ClearSkinSpecialist
  display: flex
  align-items: center

.ClearSkinSpecialistName
  color: blue01
  display: flex
  margin: 0
  font-size: 16px
  font-weight: 600

.Link
  color: orange01

.CheckIn
  display: flex
  flex-direction: column

.ActionButton
  color: turquoise01
  font-size: 16px
  line-height: 45px

.Note
  display: flex
  flex-direction: column
  font-size: 14px

.NoteDate
  display: flex
  font-weight: 600

.NoteActions
  display: flex

@import '~styles/variables';
@import '~styles/mixins';

.DocumentList {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px 0 $spacing-triple;
  font-size: 1rem;
  font-weight: 400;
  box-sizing: border-box;

  @include mediaQuery(max, 600px) {
    display: none;
  }

  span {
    display: block;
    position: relative;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $almost-black;
      position: absolute;
      right: -18px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  ul {
    list-style-type: none;
    position: absolute;
    right: -20px;
    top: 38px;
    background-color: #fff;
    padding: 18px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    transition: transform 0.15s $curve;
    transform-origin: 0 0;
    transform: scaleY(0);
    overflow-y: scroll;
    max-height: 350px;
    z-index: 10;
  }

  &:hover ul {
    transform: scaleY(1);
  }

  li {
    white-space: nowrap;
  }

  li + li {
    margin-top: 18px;
  }
}

@value white01, border01, darkGrey, black01 from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.MessageList
  display: flex
  flex-grow: 1
  flex-shrink: 1
  flex-direction: column-reverse
  justify-content: flex-start
  overflow-y: auto
  padding: 0 2em
  margin: 1em 0

.MessageList::after
  content: ''
  display: flex
  height: 25px

.Day
  display: flex
  flex: 1 0
  flex-direction: column-reverse

.DayHeader
  display: flex
  justify-content: center
  color: darkGrey
  position: relative

.DayHeader::before
  content: ''
  height: 1px
  background-color: border01
  left: 0
  right: 0
  top: 0
  bottom: 0
  margin: auto
  position: absolute
  z-index: 1

.DayHeader > span
  background-color: white01
  text-align: center
  width: 200px
  font-size: 0.85em
  font-weight: bold
  padding: 8px
  z-index: 2

.Message
  display: flex
  flex: 1 0
  padding: 12px 0

.LoadingNextMessages
  display: flex
  justify-content: center
  padding: 1em
  margin: 0.5em 0
  user-select: none
  pointer-events: none

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .MessageList
    padding: 0

@import '~styles/variables';
@import '~styles/mixins';

.dashboardRoot {
  display: grid;
  grid-template: 1fr / 24px 1fr 24px;

  @include for-tablet-landscape-up {
    grid-template: 1fr / 48px 1fr 48px;
  }

  .dashboardGreeting {
    color: $almost-black;
    font-size: 2rem;
    line-height: 2rem;
    padding: $spacing-triple 0;
  }

  .marginFooter {
    margin-bottom: 240px;
  }
}

.NextCheckInDate {
  font-size: 22px;
  margin-top: 1em;
}

.dashboardContentContainer {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: auto;
  grid-column-start: 2;
}

.dashboardRow {
  width: 100%;
  padding: 0 0;

  @include for-tablet-landscape-up {
    padding: 0 40px 32px 0;
    display: grid;
    grid-template: 1fr / 8fr 1fr 3fr;
  }
}

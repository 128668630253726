@import '~styles/variables';

$inputWidth: 240px;

.listHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.listHeaderContainer h2 {
  color: $turquoise;
  font-size: 1.6em;
  font-weight: 200;
  max-width: calc(100% - #{$inputWidth});
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// override global style in lists.scss
div.searchInputContainer {
  width: $inputWidth;
}

:global(.document-list-container .search-input-container) {
  display: none;
}

@value black01, errorRed from '~styles/colors.sss'

.TextInput
  position: relative

.TextInput > input
  border: 1px solid black01
  border-radius: 4px
  box-sizing: border-box
  height: 48px
  width: 100%
  padding: 0.5em 1em
  font-size: 1rem
  line-height: 1.5rem

.TextInput > svg
  position: absolute
  left: 15px
  top: 0
  bottom: 0
  margin: auto 0

.IconDivider
  padding-left: 3em !important

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

@value forTabletPortraitDown from '~styles/media.sss'

.Login
  height: 100vh
  display: flex

.LeftPane
  align-items: center
  background-image: url('https://cc-production-media.s3-us-west-2.amazonaws.com/static/login_splash.jpg')
  background-size: cover
  display: flex
  flex: 1
  justify-content: center

.LogoImage
  position: relative
  margin: auto
  height: auto
  max-height: 120px
  width: auto
  max-width: 95%

.Eula
  margin-top: 4em
  font-size: 1.3em

.RightPane
  align-items: center
  display: flex
  flex: 1
  justify-content: center

.RightPaneFill
  display: flex
  flex-direction: column
  padding: 0 2em
  flex-basis: 420px

forTabletPortraitDown
  .LeftPane
    display: none

@value turquoise01 from '~styles/colors.sss'
@value forTabletLandscapeUp, forPhoneOnly from '~styles/media.sss'

.Plans
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  max-width: 1190px
  padding: 0 25px
  margin: 0 auto

.Plans > h1
  line-height: 1.3
  font-size: 47px
  letter-spacing: 0
  font-weight: 500
  font-family: 'Proxima Nova', sans-serif
  margin-bottom: 35px

.Green
  color: turquoise01

.Orange
  font-size: 17px
  font-weight: normal
  margin-bottom: 15px
  color: #FF9F1C

.SubTitle
  line-height: 1.6
  font-size: 14px
  letter-spacing: 3px
  margin-bottom: 50px
  font-weight: 500

.Plans > ul
  display: flex
  flex-wrap: wrap
  justify-content: center
  margin-top: 50px

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .Plans > ul
    flex-direction: row
    min-width: 950px

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forPhoneOnly
  .Plans
    width: 100%

  .Plans > h1
    line-height: 1
    font-size: 37px

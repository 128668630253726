@value white01, turquoise01 from '~styles/colors.sss'

.Modal
  display: flex
  align-items: center
  justify-content: center
  margin: auto

.Content
  background-color: white01
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05), -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06), -2px -2px 2px 0 rgba(0, 0, 0, 0.07)
  border-radius: 4px
  padding: 2em
  position: relative
  max-height: 100%
  display: flex
  flex-direction: column

.Top
  display: flex
  margin-bottom: 2em

.Title
  display: flex
  flex-grow: 2
  color: turquoise01
  margin: 0

.Actions
  display: flex
  flex-grow: 1
  flex-shrink: 0
  justify-content: flex-end
  margin-left: 2em

.Body
  overflow: auto
  max-height: 100%
  display: flex
  justify-content: center
  padding: 0.2rem 0

// sizes

.small
  max-width: 512px
  flex: 0 0 34%

.medium
  flex: 0 0 50%
  max-width: 768px

.large
  flex: 0 0 82%
  max-width: 1024px

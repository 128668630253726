@value white01, turquoise01 from '~styles/colors.sss'
@value forTabletLandscapeUp, forPhoneOnly from '~styles/media.sss'

.Highlights
  display: flex
  flex-direction: column
  max-width: 1190px
  margin: 0 auto
  padding: 0 2em

.HighlightsContent
  display: flex
  flex-direction: column
  background-color: white01
  padding: 1em

.Left
  display: flex
  flex-direction: column
  width: 100%
  color: #3D3E36
  letter-spacing: 0
  text-align: center
  align-items: center

.Left > h1
  text-align: center
  margin-bottom: 0.5em
  font-weight: bold
  color: #0B4F6C
  line-height: 100%
  font-size: 2.6em
  font-family: 'Proxima Nova', sans-serif

.Left > h3
  font-size: 15px
  font-weight: 700

.Left > p
  font-size: 15px
  margin-bottom: 1em
  line-height: 1.95

.Left > p:last-child
  font-size: 17px
  padding: 1em 0
  text-align: center
  font-weight: 700

.Left > h2
  font-size: 15px
  margin-bottom: 3em
  font-weight: 700

.Right
  display: flex
  align-items: center
  flex-direction: column
  width: 100%

.CEO
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 2em
  font-weight: 500
  margin-right: 69px
  margin-top: 23px
  text-align: center

.Name
  font-weight: 800
  font-size: 18px
  color: #0B4F6C
  display: block
  margin-bottom: 10px
  letter-spacing: 0.5px

.Right > img
  max-width: 100%
  height: auto

.CEO > h1
  font-size: 20px
  margin: 0.5em

.CEO > h2
  font-size: 12px

.CEO > p
  font-size: 12px
  font-style: italic

.StayClearButton
  background-color: #FBA035
  color: white01
  width: 100%
  border-radius: 4px
  text-align: center
  text-decoration: none
  padding: 20px 0
  letter-spacing: 2px
  font-weight: bold
  font-size: 13px
  max-width: 264px
  margin-top: 40px
  line-height: 1.95

.StayClearButton > h1
  margin: 0.5em 0
  font-weight: 600
  color: white01
  font-size: 15px

.StayClearButton > p
  padding: 0
  font-size: 10px
  color: white01

.Left .Title
  text-align: center
  line-height: 1.3
  font-size: 44px
  letter-spacing: 0
  font-weight: 500
  margin: 0 0 10px 0

.Green
  color: turquoise01

.Checked
  list-style-type: none

.Checked > li
  margin-left: 50px
  font-size: 18px

.Checked > li::before
  content: ''
  background-image: url('~assets/images/checkMark.png')
  width: 35px
  height: 35px
  background-size: contain
  background-repeat: no-repeat
  display: block
  position: relative
  top: 40px
  left: -50px

.Content
  display: flex
  flex-direction: column
  text-align: left
  max-width: 900px
  margin: 0 auto
  font-size: 18px

.Image
  display: flex
  flex: 1
  flex-direction: column
  align-items: center
  justify-content: center

.Image img
  max-width: 100%

.Description
  display: flex
  flex: 1
  margin-top: 1.5em
  flex-direction: column

.Description h1
  font-size: 24px

.Description p
  color: black01
  font-size: 17px
  line-height: 2

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .HighlightsContent
    flex-direction: row

  .Left
    width: 50%

  .Right
    width: 50%

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forPhoneOnly
  .Highlights
    padding: 0

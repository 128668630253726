@value black01, errorRed, darkGrey from '~styles/colors.sss'

.Autocomplete
  position: relative

.Autocomplete > svg
  position: absolute
  left: 15px
  top: 0
  bottom: 0
  margin: auto 0

.IconDivider
  padding-left: 3em !important

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

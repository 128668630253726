
.SuccessModal
  padding: 0 2em

.Title
  font-size: 24px
  font-weight: 500
  margin: 1em 0

.Footer
  display: flex
  margin-top: 4rem
  justify-content: center

@import '~styles/variables';

.selectAsrBtn {
  background-color: $turquoise;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 18px;
  margin: 1rem auto;
  padding: 1rem 3rem;
}

.modal {
  background-color: rgba(11, 79, 108, 0.88);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 99999999;
}

.modalContainer {
  background-color: $white;
  border-radius: 4px;
  box-shadow: inset 0 -1px 2px rgba(0,0,0,0.05), -2px -1px 5px 0 rgba(0,0,0,0.10), -2px -3px 1px -2px rgba(0,0,0,0.06), -2px -2px 2px 0 rgba(0,0,0,0.07);
  display: inline-block;
  margin-top: 10vh;
  padding: 2rem 3rem;
  text-align: center;
  width: 24rem;

  > header {
    position: relative;
  }

  > div {

    select {
      margin-top: 2rem;
      min-width: 8rem;
    }
  }

  > footer {
    margin-top: 4rem;

    button {
      background-color: $turquoise;
      border-radius: 4px;
      color: $white;
      cursor: pointer;
      font-size: 18px;
      margin-left: 1rem;
      padding: 0.75rem 3rem;

      &:disabled {
        cursor: text;
        opacity: 0.5;
      }
    }
  }
}

.closeModal {
  // !important, as this is set globally in `src/styles/lists.scss`
  background-color: transparent;
  cursor: pointer;
  font-size: 24px !important;
  font-weight: 100 !important;
  height: auto !important;
  padding: 1rem;
  position: absolute !important;
  right: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}

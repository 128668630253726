
.HQPhotosWidget
  position: relative
  min-height: 200px

.HQPhotosHeader
  padding: 10px 0

.HQMessage
  font-weight: normal

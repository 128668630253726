@import 'variables';
@import 'mixins';
@import 'lists';
@import 'react-viewer';

:root {
  --preferred-font: 'Open Sans', sans-serif;
}

html,
body {
  font-size: 16px;
}

html {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
header {
  font-family: Muli, sans-serif;
}

h1 {
  font-size: 2rem;
  color: $almost-black;
  font-weight: 300;
}

h2 {
  font-size: 18px;
  @include for-tablet-portrait-up {
    font-size: 24px;
  }
}

ul,
li {
  padding: 0;
  margin: 0;
}

button {
  border: none;
  outline: none;
}

header,
footer,
section,
div {
  box-sizing: border-box;
}

/**
 * default card
 */

:global([data-react-toolbox='card']) {
  width: 550px;
  max-width: 100%;
  margin: $spacing-xl auto;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 3px 0 rgba(0, 0, 0, 0.1);
  [class*='cardTitle'] {
    background-color: $white;
    padding-left: $spacing-triple !important;
    color: $turquoise;
    > div {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      h5 {
        font-size: 1.25rem !important;
      }
      > div:last-child {
        cursor: pointer;
        color: $orange;
      }
    }
  }
  [class*='cardText'] {
    padding: $spacing-standard $spacing-triple $spacing-triple !important;
  }
  [class*='inlineItemsWrapper'] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
      width: 47%;
    }
    @include for-phone-only {
      flex-direction: column;
      > div {
        width: 100%;
      }
      margin-bottom: $spacing-triple;
    }
  }
  .inlineItemsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
      width: 47%;
    }
    @include for-phone-only {
      flex-direction: column;
      > div {
        width: 100%;
      }
      margin-bottom: $spacing-triple;
    }
  }
  .titleButton {
    cursor: pointer;
    color: $orange;
  }
}

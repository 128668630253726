@value white01 from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.CompleteSurvey
  display: flex
  flex-direction: column

.Form
  background-color: white01
  padding: 1em
  border-radius: 5px

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .CompleteSurvey
    max-width: 100%

  .Form
    padding: 4em 2em 2em 2em

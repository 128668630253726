@value black01, errorRed, darkGrey from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.MultipleChoice
  display: flex
  flex-direction: column

.MultipleChoice > ul
  display: flex
  flex-direction: column
  list-style-type: none

.MultipleChoice > ul > li
  display: flex
  flex-direction: column

.Choice
  display: flex
  align-items: center
  margin: 0.5em 0

.Choice label
  margin-left: 1em

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .VerticalTwoCol > ul
    display: grid
    grid-template-columns: 1fr 1fr

@import '~styles/variables';

.filtersContainer {
  list-style-type: none;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(0,0,0,0.05);
  border-radius: 3px;
}

.filtersContainer li {
  font-size: 0.85em;
  color: $almost-black;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;

  span {
    white-space: nowrap;
  }

  & + li {
    border-top: 1px solid $light-grey;
  }
}

.filtersContainer li.activeFilter {
  font-weight: bold;
  color: $turquoise;
}

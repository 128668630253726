@value white01 from '~styles/colors.sss'
@value curve from '~styles/utils.sss'
@value inboxSidebarWidth: 330px
@value navbarHeight: 64px

.NotificationListWidget
  width: inboxSidebarWidth
  position: fixed
  top: navbarHeight
  bottom: 0
  right: 0
  z-index: 5
  overflow: hidden
  background-color: white01

  // inactive state
  transition: transform 0.25s curve
  transform: translateX(100%)

.NotificationListWidget::-webkit-scrollbar
  display: none

.NotificationListWidget > header
  height: 40px
  position: relative
  z-index: 1
  padding: 0 18px
  display: flex
  align-items: center
  justify-content: space-between
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.07), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1)

.Empty
  margin: 1em

.Open
  transform: translateX(0)
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.15)

.changeProgramWeeks {

  p {
    margin-bottom: 1rem;
  }

  button {
    cursor: pointer;
    margin: 0;
    padding: 0;

    i {
      font-size: 1rem;
      position: relative;
      top: 1px;
    }
  }

  select {
    display: inline-block;
    margin-left: 0.325rem;
  }

}

@value turquoise01 from '~styles/colors.sss'

.AttachmentPreview
  position: relative
  display: flex
  justify-content: center
  background-color: turquoise01

.CloseAttachment
  position: absolute
  right: 0
  top: 0

.ImagePreview
  width: 150px
  height: 150px

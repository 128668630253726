@value white01, turquoise01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.Banner
  display: flex
  flex-direction: column
  width: 100%
  align-items: center

.Title
  max-width: 900px
  line-height: 1.3
  font-size: 2.9em
  letter-spacing: 0
  font-weight: 500
  margin: 0
  color: white01

.Green
  color: turquoise01

.ViewPlansButton
  background-color: #FBA035
  color: white01
  width: 100%
  max-width: 150px
  border-radius: 4px
  text-align: center
  padding: 20px 0
  letter-spacing: 2px
  font-weight: bold
  font-size: 13px
  margin-top: 70px

@value black01, turquoise01 from '~styles/colors.sss'

.Rejection
  color: black01

.Title
  font-size: 22px
  color: turquoise01

.Subtitle
  color: black01
  font-weight: 600
  font-size: 18px
  margin: 2em 0

.ReasonList
  margin: 1em 0
  padding-left: 2em

.OtherReason
  margin: 1em 0
  padding-left: 2em

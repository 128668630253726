@value turquoise01, orange01 from '~styles/colors.sss'

.Title
  color: turquoise01
  font-size: 1.25rem
  display: flex
  justify-content: flex-start
  align-items: center
  margin-bottom: 16px

.Title h1
  color: turquoise01
  font-size: 22px
  font-weight: 400
  margin-left: 1em

.Empty
  font-size: 22px

.List
  list-style: none

.Link
  flex-grow: 1
  text-align: right
  font-size: 16px

@value white01 from '~styles/colors.sss'
@value forPhoneOnly from '~styles/media.sss'

.Plan
  display: block
  flex-direction: column
  align-items: center
  width: 350px
  margin-right: 30px
  box-sizing: border-box
  border-radius: 10px
  padding: 20px
  position: relative
  margin-bottom: 90px

.Basic
  border: 3px solid #86C1C9

.ClearForAYear
  border: 3px solid #D19AC2

.ClearForLife
  border: 3px solid #90C391

.Basic > label
  background: #86C1C9

.ClearForAYear > label
  background: #D19AC2

.ClearForLife > label
  background: #90C391

.Plan > h1
  font-size: 30px
  font-weight: bold
  font-family: 'Proxima Nova', sans-serif

.Plan > label
  color: white01
  font-weight: 500
  border-radius: 5px
  padding: 15px
  display: flex
  justify-content: center
  align-items: center
  font-size: 25px
  margin-top: 15px

.Plan > ul
  font-size: 17px
  list-style: disc
  align-items: center
  padding: 0
  margin-top: 30px
  text-align: left
  margin-left: 20px

.Plan > ul > li
  margin-bottom: 15px
  text-align: left

.ViewPlansButton
  position: absolute
  bottom: -90px
  transform: translateX(-50%)
  background-color: #FBA035
  color: white01
  width: 100%
  max-width: 150px
  border-radius: 4px
  padding: 20px 0
  letter-spacing: 2px
  font-weight: bold
  font-size: 13px

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forPhoneOnly
  .Plan
    margin-right: 0
    width: 100%

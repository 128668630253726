@value lightTurquoise, lighterTurquoise from '~styles/colors.sss'

.MenuItem
  height: 64px
  width: 100%
  min-width: 64px
  display: flex
  align-items: center
  justify-content: center

.Circle
  padding: 6px
  background-color: lighterTurquoise
  border-radius: 50%

.Circle:active
  pointer-events: none

.Circle:hover
  background-color: lightTurquoise

@value blue01, turquoise01 from '~styles/colors.sss'

.LoginForm
  width: 100%

.FormTitle
  color: turquoise01
  font-size: 36px
  font-weight: bold

.Login
  display: flex
  flex-direction: column

.VerticalGroup
  display: flex
  flex-direction: column
  margin: 0.8em 0

.InputGroup
  display: flex
  flex-direction: column
  flex: 1

.Label
  font-size: 14px
  margin: 0.5em 0

.Link
  color: blue01
  margin-top: 0
  padding-left: 0
  font-size: 16px

.Footer
  margin-top: 1em

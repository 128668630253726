@value forPhoneOnly from '~styles/media.sss'

.GeneralInformationForm
  display: flex
  flex-direction: column

.SectionTitle
  margin: 1.5em 0
  text-align: center

.FormTitle
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  font-size: 16px
  margin: 1em 0

.HorizontalGroup
  display: flex
  align-items: center
  margin: 0.8em 0

.InputGroup
  display: flex
  flex-direction: column
  flex: 1

.Label
  font-size: 14px
  margin: 0.5em 0

.HorizontalGroup .InputGroup:not(:first-child)
  margin-left: 1em

.FormFooter
  text-align: center
  margin: 2em 0

forPhoneOnly
  .HorizontalGroup
    flex-direction: column
    justify-content: start

  .HorizontalGroup .InputGroup:not(:first-child)
    margin-left: 0
    margin-top: 1em

@value white01, turquoise01 from '~styles/colors.sss'
@value forPhoneOnly from '~styles/media.sss'

.Task
  align-items: center
  border-radius: 4px
  border: 0.5px solid rgba(0, 0, 0, 0.1)
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1)
  font-size: 0.875rem
  line-height: 1.125rem
  margin: 8px 1px
  padding: 0.5rem 0.75rem
  transition: box-shadow 0.5s ease-out

.TaskRow
  align-items: center
  display: flex
  font-size: 0.875rem
  justify-content: space-between
  line-height: 1.125rem
  margin: 8px 1px
  padding: 0.5rem 0.75rem
  transition: box-shadow 0.5s ease-out

.Description
  display: flex
  flex-direction: column

.Title
  display: flex

.NoteBody
  display: flex
  max-width: 80%

.Subtitle
  display: flex
  font-size: 12px

.Action
  display: flex

.Link
  background-color: turquoise01
  border-radius: 4px
  color: white01
  cursor: pointer
  margin-left: 1.5rem
  padding: 12px 28px
  text-decoration: none

.Button
  padding: 0.75rem 1.75rem
  margin-top: 0
  margin-bottom: 0

.Additional
  margin-left: 0.5rem

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forPhoneOnly
  .Task
    display: block

  .Button
    margin-top: 1rem

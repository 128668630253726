@import '~styles/variables';

.StepOptions {
  display: flex;
  margin: 1rem 0;

  select {
    display: flex;
    flex: 1;
    background-color: #F9F9F9;
    border: 1px solid $almost-black;
    width: 100%
  }
}

.StepQty {
  display: flex;
  flex-shrink: 0;
}

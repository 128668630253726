@value turquoise01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.Question
  margin: 1em 0

.Question > div
  display: flex
  flex-basis: 100%

.Question label:first-of-type
  font-weight: 600
  text-align: justify

/* Specific classes by Questions */

.SelectASR ul
  min-height: 700px
  flex-direction: column

.SelectASR img
  height: auto
  transition: opacity 0.3s ease, width 1s
  width: 80px

.SelectASR img:hover
  /* .SelectASR img:hover */
  width: 210px
  transition: width 1s

.SelectASR label > div
  display: flex
  flex-direction: column
  align-items: center

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .SelectASR ul
    flex-direction: row
    min-height: 320px

@value white01, turquoise01, orange01, errorRed from '~styles/colors.sss'

.BadgeButton
  align-items: center
  background-color: white01
  border-color: orange01
  border-style: solid
  border-width: 0.1em
  border-radius: 2em
  color: orange01
  cursor: pointer
  display: flex
  padding: 0.5em 1em 0.5em 0.5em
  margin: 0
  user-select: none
  font-size: 14px
  font-style: inherit
  font-family: inherit
  outline: 0
  line-height: normal

.BadgeButtonActive
  background-color: orange01
  color: white01

.BadgeButton:disabled
  cursor: text
  opacity: 0.5

.Badge
  justify-content: center
  margin-right: 0.5em
  border-radius: 50%
  background-color: orange01
  color: white01
  font-size: 12px
  font-weight: 800
  line-height: 2.5
  align-items: center
  display: flex
  width: 24px
  height: 24px

.BadgeActive
  background-color: white01
  color: orange01

.BadgeText
  font-weight: 800
  color: orange01

.BadgeTextActive
  color: white01

@value black01, orange01, turquoise01  from '~styles/colors.sss'
@value forTabletPortraitDown from '~styles/media.sss'

.SurveyRemote
  color: black01
  display: grid
  grid-template: 160px 1fr / 48px 1fr 48px
  padding: 0

.SurveyRemoteHeader
  display: flex
  flex-direction: column
  align-items: flex-start
  grid-column-start: 2

.SurveyResults
  display: grid
  grid-column-start: 2
  grid-template: 1fr / 1fr 3fr

.SurveyResultsNav
  flex: 0 0 260px
  max-width: 260px
  min-height: 200px
  padding: 0 18px 0 0

.PageLink
  height: 40px
  display: flex
  align-items: center
  cursor: pointer
  font-size: 0.875rem
  text-decoration: none
  transition: text-shadow 0.15s ease-out

.PageList
  list-style-type: none
  color: orange01
  font-weight: 600
  overflow: hidden

.clientName
  color: orange01

.clientLinks
  cursor: pointer

a
  text-decoration: none

.ResultsPages
  margin: -24px 0 320px 24px
  grid-column-start: 2

.PageSection
  padding: 8px

.PageTitle
  font-size: 1.625rem
  color: turquoise01
  padding: 16px 0

.QuestionContainer
  margin-bottom: 1rem

.Question
  font-weight: 600
  padding: 4px 0

.Answer
  padding-bottom: 8px

.PhotoImage
  height: auto
  width: auto
  max-height: 100%
  max-width: 100%

.IconContainer
  margin-right: 12px
  width: 20px
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  color: orange01

.Icon
  fill: orange01


forTabletPortraitDown
  .SurveyRemote
    grid-template: 160px 1fr / 24px 1fr 24px
    padding: 0

  .SurveyResults
    grid-template: auto auto / 1fr

  .SurveyResultsNav
    grid-row-start: 1
    margin-bottom: 32px
    max-width: none
    width: 100%

  .ResultsPages
    grid-row-start: 2
    grid-column-start: 1
    margin: 0

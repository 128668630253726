@value blue01 from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.Survey
  background-color: blue01
  display: flex
  justify-content: center
  align-items: center
  padding: 2em 0

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .ActiveStep
    max-width: 800px

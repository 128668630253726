@value white01, turquoise01, orange01, border01 from '~styles/colors.sss'
@value forTabletLandscapeUp from '~styles/media.sss'

.Order
  padding: 32px 48px

.OrderTitle
  color: turquoise01
  font-size: 21px
  font-weight: 300
  margin-bottom: 24px

.ProductsContainer
  padding-bottom: 6rem

.CategoriesList
  list-style: none
  margin: 2rem 0

.CategoriesList li
  border-bottom: 1px solid border01
  margin: 2rem 0
  padding-bottom: 2rem

.ProductGrid
  margin-top: 1rem
  width: 100%

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .ProductGrid
    display: flex
    flex-wrap: wrap

.Footer
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05), -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06), -2px -2px 2px 0 rgba(0, 0, 0, 0.07)
  background-color: white
  position: fixed
  bottom: 0
  left: 0
  right: 0
  padding: 1rem 3rem
  text-align: right
  z-index: 9999999

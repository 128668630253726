@import '~styles/variables';

.Header {
  background-color: $turquoise;
  padding: 4.5rem 3rem 2.25rem;
  text-align: center;
  text-transform: uppercase;

  h1 {
    color: $white;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.375rem;
  }
}

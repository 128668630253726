@value black01, turquoise01 from '~styles/colors.sss'

.FormItem
  display: flex

.FormItemFill
  flex: 1

.VerticalFormItem
  composes: FormItem
  flex-direction: column

.HorizontalFormItem
  composes: FormItem
  flex-direction: row
  align-items: center
  margin-right: 1em

.ReverseFormItem
  flex-direction: row-reverse

.Label
  color: black01
  display: flex
  font-size: 14px
  text-align: justify

.InlineLabel
  composes: Label
  margin-right: 0.5em

.VerticalLabel
  display: flex
  margin-bottom: 0.5em

.ReverseLabel
  margin-right: 0
  margin-left: 0.5em

.RequiredMark
  font-size: 18px
  margin-right: 5px
  color: turquoise01

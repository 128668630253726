@value errorRed, border01 from '~styles/colors.sss'

.FileInput
  cursor: pointer
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.FileInput input
  display: none

.FileInput label
  display: flex
  cursor: pointer
  justify-content: center
  width: 100%
  position: relative
  padding-bottom: 50%

.Content
  cursor: pointer
  pointer-events: none

.ErrorText
  color: errorRed

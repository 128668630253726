@value white01, turquoise01 from '~styles/colors.sss'

.Quote
  margin-top: 2em
  display: flex
  flex-direction: column
  align-items: center
  background-color: turquoise01
  width: 100%
  padding: 1.5em 0

.Quote > p
  max-width: 690px
  font-style: italic
  color: white01
  line-height: 1.5

.Quote > h1
  margin: 0
  font-size: 18px
  color: white01
  font-weight: author

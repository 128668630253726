@value turquoise01 from '~styles/colors.sss'
@value forTabletPortraitDown from '~styles/media.sss'

.RequestNewToken
  height: 100vh
  display: flex

.Title
  color: turquoise01
  font-size: 36px
  font-weight: bold

.LeftPane
  align-items: center
  background-image: url('https://cc-production-media.s3-us-west-2.amazonaws.com/static/login_splash.jpg')
  background-size: cover
  display: flex
  flex: 1
  justify-content: center

.LeftPane img
  position: relative
  margin: auto
  height: auto
  max-height: 120px
  width: auto
  max-width: 95%

.RightPane
  align-items: center
  display: flex
  flex: 1
  justify-content: center

.RightPaneFill
  display: flex
  padding: 0 2em
  flex-basis: 420px

.ResendContainer
  display: flex
  flex-direction: column
  height: 180px
  justify-content: space-between

forTabletPortraitDown
  .LeftPane
    display: none

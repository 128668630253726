@import '~styles/variables';

.surveyRouterContainer {
  background-color: $blue;
  width: 100%;
  padding: 0 32px;

  @include for-phone-only {
    padding: 0;
  }
}

@value white01 from '~styles/colors.sss'

.Dashboard
  background: white01

.AdminWrapper
  display: flex
  flex-grow: 1
  flex-direction: column
  justify-content: center
  align-items: center


.ThreadList
  box-sizing: border-box
  display: flex
  flex: 1 0
  flex-basis: auto
  flex-direction: column
  list-style-type: none
  width: 100%

.Empty
  margin: 1em

.Thread
  display: flex
  margin: 0.5em 0
  flex-shrink: 0


.CheckinPhotosWidget
  position: relative
  min-height: 200px

.CheckinPhotosHeader
  padding: 10px 0

.CheckinMessage
  font-weight: normal

@value black01, border01, cardShadow, errorRed from '~styles/colors.sss'
@value forTabletPortraitDown from '~styles/media.sss'

.ProgramConsent
  border: 1px solid border
  border-radius: 4px
  box-shadow: cardShadow
  margin: 48px auto 0
  max-width: 850px
  padding: 24px 48px
  width: calc(90% - 96px)

.ProgramConsent h2
  margin-bottom: 10px
  text-align: center

.subtitle  p
  margin-bottom: 15px
  text-align: center
  font-size: 16px
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  text-align: justify

.FormItem
  align-items: flex-start

.StatementSignature
  border-bottom: 1px solid black01
  height: 22px
  margin-right: 10px
  padding: 0
  width: 55px !important
  text-align: center

.signature
  /* margin: 30px 0 */
  justify-content: center

.signature p
  display: block
  line-height: 20px
  margin-bottom: 10px

.UserName::placeholder
  text-align: center

.agreementTitle
  margin-bottom: 15px

.Buttons
  display: flex
  justify-content: flex-end

.initials
  border-bottom: 1px solid black01
  height: 22px
  margin-right: 10px
  padding: 0
  width: 65px

.ErrorText
  color: errorRed
  margin: auto

forTabletPortraitDown
  .ProgramConsent
    margin-top: 24px

  .UserName
    min-width: 100%

@value white01, black01, errorRed from '~styles/colors.sss'

.RegionSelector
  background-color: white01
  border: 1px solid black01
  height: 48px
  width: 100%

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

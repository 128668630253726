@value white01, turquoise01, orange01, bluePrimary from '~styles/colors.sss'

.RegimenHeader
  background-color: turquoise01
  padding: 4.5rem 3rem 2.25rem
  text-align: center
  text-transform: uppercase

.RegimenHeader h1
  color: white01
  font-weight: bold
  letter-spacing: 0.375rem

.DraftedHeader
  background-color: orange01
  opacity: 0.8

.PublishedHeader
  background-color: bluePrimary
  opacity: 0.8

.RegimenHeader h2
  color: white01
  font-weight: 400
  letter-spacing: 0.375rem

.RegimenContainer
  padding: 2.25rem 3rem 8rem

.ViewNotesButton
  margin: 1em 0

.ViewNotesText
  color: orange01
  position: relative
  bottom: 8px

@value white01, turquoise01 from '~styles/colors.sss'
@value curve from '~styles/utils.sss'
@value inboxSidebarWidth: 350px
@value navbarHeight: 64px

.InboxWidget
  width: inboxSidebarWidth
  position: fixed
  display: flex
  flex-direction: column
  justify-content: flex-start
  height: calc(100vh - navbarHeight)
  right: 0
  z-index: 5
  background-color: white01
  padding: 2em 1em

  // inactive state
  transition: transform 0.25s curve
  transform: translateX(100%)

.InboxWidget::-webkit-scrollbar
  display: none

.Open
  transform: translateX(0)
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.15)

.Filters
  display: flex
  justify-content: space-evenly

.Title
  border-bottom: 1px solid
  color: turquoise01
  font-weight: 800
  display: flex
  margin: 0
  padding: 0 0 1em 0
  font-size: 18px
  margin-bottom: 1em
  letter-spacing: 2px
  text-transform: uppercase

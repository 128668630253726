@value turquoise01 from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.Title
  color: turquoise01
  font-size: 16px
  margin: 1em 0
  font-weight: 300

.Reason
  margin: 1em 0

.Reason label
  font-size: 12px
  margin-left: 1em
  cursor: pointer

.Actions
  display: flex
  align-items: center
  flex-direction: column
  margin-top: 4em

.Action
  min-width: 140px
  margin: 1em

.CancelAction
  position: absolute
  bottom: -80px
  margin: 0 auto

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .Title
    font-size: 21px

  .Reason
    margin: 1em 2em

  .Reason label
    font-size: 16px

@import '~styles/variables';

.regimenStep {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  padding: 1rem;

  @include for-tablet-landscape-up {
    width: calc(50% - 3.5rem);

    &:nth-of-type(odd) {
      margin-right: 1rem;
    }
    &:nth-of-type(even) {
      margin-left: 1rem;
    }
  }

  .stepToggle {
    margin-right: 0.5rem;
    position: relative;
    top: -2px;
  }

  .stepName {
    font-size: 1.125rem;
    font-weight: 700;

    &.disabled {
      opacity: 0.3;
    }
  }

  .stepInstructions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1.5rem;
    padding-top: 1rem;

    &:hover {
      .editInstructions > button i.editIcon {
        opacity: 1;
      }
    }
    .stepInstructionPreview {
      font-size: 0.9375rem;
      margin-bottom: 1rem;
    }
  }

  .editInstructions {
    margin-bottom: 1rem;
    position: relative;

    &.previewingInstructions {
      cursor: pointer;
    }

    > h4 {
      font-size: 0.875rem;
    }

    > button {
      background-color: transparent;
      cursor: pointer;
      padding: 0.5rem;
      position: absolute;
      right: -0.5rem;
      top: -0.5rem;
    }
  }

  .orderingInstructions {
    color: $dark-grey;
    font-size: 0.75rem;
    font-style: italic;
    margin: 1rem 0;
  }
}

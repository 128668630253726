@import '~styles/variables';

.modal {
  background-color: rgba(11, 79, 108, 0.88);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 100;
}

.modalContainer {
  background-color: $white;
  border-radius: 4px;
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05),
    -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06),
    -2px -2px 2px 0 rgba(0, 0, 0, 0.07);
  display: inline-block;
  margin-top: 10vh;
  max-width: 44rem;
  padding: 2rem 3rem;
  text-align: left;

  > header {
    padding-right: 2rem;
    position: relative;

    h4 {
      color: $turquoise;
      font-size: 21px;
      font-weight: 300;
      margin-bottom: 1.5rem;
    }
  }

  input {
    border-radius: 3px;
    border: 2px solid $grey;
    height: 2.5rem;
    font-size: 1rem;
    line-height: 2.5rem;
    margin: 0.5rem;
    outline: none;
    padding: 0 0.75rem;
  }

  > footer {
    margin-top: 4rem;
    text-align: right;

    button {
      background-color: $turquoise;
      border-radius: 4px;
      color: $white;
      cursor: pointer;
      font-size: 18px;
      margin-left: 1rem;
      padding: 0.75rem 3rem;

      &:disabled {
        cursor: text;
        opacity: 0.5;
      }
    }
  }
}

.modalInput {
  display: flex;

  label {
    align-self: center;
  }
}

.closeModal {
  // !important, as this is set globally in `src/styles/lists.scss`
  background-color: transparent;
  cursor: pointer;
  font-size: 24px !important;
  font-weight: 100 !important;
  height: auto !important;
  padding: 1rem;
  position: absolute !important;
  right: -1rem !important;
  top: 50%;
  transform: translateY(-50%);
}

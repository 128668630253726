@value forPhoneOnly from '~styles/media.sss'

.AddPersonalInformation
  margin: 2em auto
  max-width: 600px

.Title
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  margin: 2em 0
  text-align: center

.SectionTitle
  margin: 1.5em 0
  text-align: center

.FormTitle
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  font-size: 16px
  margin: 1em 0

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forPhoneOnly
  .AddPersonalInformation
    padding: 0 1em

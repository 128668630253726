@value lighterGrey, darkGrey, bluePrimary, bluePrimaryDark, errorRed from '~styles/colors.sss'

.ErrorText
  color: errorRed

.EditorContent > div
  height: 100%
  padding: 0.8rem
  overflow-y: scroll
  border: 2px solid lightGrey
  outline-color: darkGrey

.EditorContent > div > ul, ol
  padding: 0 1rem
  margin: 1.25rem 1rem 1.25rem 0.4rem

.EditorContent > div > ul, ol > li p
  margin-top: 0.25em
  margin-bottom: 0.25em

.EditorContent > div > blockquote
  border-left: 3px solid darkGrey
  margin: 1.5rem 0
  padding-left: 1rem

.EditorContent > div a
  color: bluePrimary
  cursor: pointer
  text-decoration: underline

.EditorContent > div a:hover
  color: bluePrimaryDark

@import '~styles/variables';

.Regimen {
  border-top: 1px solid $border;
  padding-top: 2rem;

  > h2 {
    cursor: pointer;
    display: flex;
    font-size: 1.75rem;
    font-weight: 400;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: none;
  }

  .listTitle {
    margin: 1rem 0 2rem;

    > h3 {
      margin-bottom: 1.5rem;
    }
  }

}

.RegimenSteps {
  border-left: 1px solid $border;
  padding-left: 1rem;

  .step {
    margin-bottom: 1.25rem;

    > h4 {
      margin-bottom: 0.5rem;
    }
  }
}

.DietRecommendationSteps {
  border-left: 1px solid $border;
  padding-left: 1rem;

  > h4 {
    margin-bottom: 0.5rem;
  }

  .steps {
    margin-bottom: 2rem;

    .step {
      margin-bottom: 0.5rem;
    }

  }

}

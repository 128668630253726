@value black01, errorRed from '~styles/colors.sss'

.Slider
  display: flex
  flex-direction: column

.InputError
  border-color: errorRed
  box-shadow: 0 0 4px errorRed

.ErrorText
  color: errorRed

@value turquoise01 from '~styles/colors.sss'

.Title
  color: turquoise01
  font-size: 21px
  margin: 1em 0
  font-weight: 300

.Description
  margin: 1em 0

.Actions
  display: flex
  align-items: center
  flex-direction: column
  margin-top: 4em

.Action
  min-width: 140px
  margin: 1em

.CancelAction
  position: absolute
  bottom: -80px
  margin: 0 auto

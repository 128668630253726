@value forTabletLandscapeUp from '~styles/media.sss'

.SingleChoice
  display: flex
  flex-direction: column

.SingleChoice ul
  list-style-type: none

.Choices
  display: flex

.Choices > li
  display: flex
  flex-direction: column

.VerticalChoices
  composes: Choices
  flex-direction: column
  justify-content: center

.HorizontalChoices
  composes: Choices
  flex-direction: column
  align-items: center

.Choice
  display: flex
  align-items: center

.VerticalChoices .Choice
  margin: 0.5em 0

.HorizontalChoices .Choice
  margin: 0 1em

.VerticalTwoCol
  display: flex
  flex-direction: column

.Choice label
  margin-left: 0.6em

.InputError
  border-color: red
  box-shadow: 0 0 4px red

.ErrorText
  color: red

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletLandscapeUp
  .VerticalTwoCol
    display: grid
    grid-template-columns: 1fr 1fr

  .HorizontalChoices
    flex-direction: row

@value black01 from '~styles/colors.sss'

.FilterItem
  color: black01
  font-size: 10px
  text-transform: uppercase
  padding: 1em 0
  margin-right: 1em
  opacity: 0.6
  cursor: pointer
  letter-spacing: 1px

.SelectedFilter
  border-bottom: 1px solid black01
  opacity: 1
  font-weight: 600

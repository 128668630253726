@value lighterGrey, lighterTurquoise from '~styles/colors.sss'

.Bottombar
  display: flex
  background-color: lighterGrey
  padding: 0.5em 1em
  align-items: center
  justify-content: flex-end

.LeftItems
  display: flex
  flex: 1 0
  align-items: center
  font-size: 14px

.Actions
  display: flex
  align-items: center
  flex-basis: 210px
  justify-content: space-between

.Extra
  background-color: lighterTurquoise
  margin-left: 2px
  width: 2px
  height: 28px

.SendMessageButton
  margin: 0

@value turquoise01 from '~styles/colors.sss'

.Title
  color: turquoise01
  font-size: 21px

.Actions
  display: flex
  justify-content: center
  margin: 2em 0

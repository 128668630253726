@import '~styles/variables.scss';
@import '~styles/mixins';

.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  height: 100vh;
  pointer-events: none;
  &.open {
    z-index: 75;
  }
  &.closed {
    z-index: 0;
  }
}

.root {
  z-index: 75;
  position: absolute;
  width: 100vw;
  height: 100%;
  @include for-tablet-portrait-up {
    display: none;
  }
  .backdrop {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0.5;
    transition: opacity 750ms ease;
  }
  .drawer {
    margin-top: 64px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: $white;
    transform: translateX(0);
    transition: transform 750ms cubic-bezier(0.16, 1.1, 0.1, 1);
    color: $almost-black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: all;
  }
}

.closed {
  .backdrop {
    opacity: 0;
    transition: opacity 750ms ease;
  }
  z-index: -75;
  transition: z-index 500ms ease 1s;
  .drawer {
    transform: translateX(100vw);
    transition: transform 750ms cubic-bezier(0.16, 1.2, 0.1, 1);
  }
}

.logoWrapper {
  height: 256px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .drawerLogo {
    height: 100%;
    width: 75%;
    background-image: url('../../assets/images/nac-horizontal-logo-dark@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    top: $spacing-double;
  }
}

.drawerLinks {
  list-style: none;
  li {
    padding: $spacing-triple;
    margin: 0 $spacing-triple;
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.875rem;
    border-bottom: 1px solid $dark-grey;
    // &:active {
    //   background-color: $blue-mobile-click-state;
    // }
  }
}

.ProgramDocuments {
  width: 100%;
  @include mediaQuery(max, 600px) {
    display: flex !important;
  }
  li {
    white-space: pre-line;
  }
}

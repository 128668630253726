@value lightGrey, white01, orange01, grey01 from '~styles/colors.sss'

.ChatRedirect
  display: flex
  flex-direction: column
  margin: auto 0
  background: lightGrey
  justify-content: center
  align-items: center
  padding: 2em 0

.ChatRedirectInfo
  display: flex
  align-items: center
  justify-content: center

.ChatRedirectLockIcon
  background: grey01
  border-radius: 0.5em
  margin: 5px
  padding: 10px

.ChatRedirectMessage
  display: flex
  flex-direction: column
  justify-content: space-between

.ChatRedirectHeadMessage
  font-size: 14px
  font-weight: 800
  font-family: SANS-SERIF
  letter-spacing: -0.49px
  margin: 0

.ChatRedirectMessageButton
  padding: 0.5em 1em
  color: white01
  background-color: orange01
  border-radius: 0.3em
  cursor: pointer

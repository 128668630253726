@import '../../../styles/variables.scss';

.root {
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    h1 {
      font-size: 2rem;
      font-weight: 400;
    }
    button {
      width: auto;
    }
  }
}

.newNote {
  .inputWrapper {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    label {
      display: block;
      padding: 4px 0;
    }
    input,
    textarea {
      border: 1px solid $almost-black;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 16px;
      padding: 8px;
      min-height: 24px;
      max-width: auto;
    }
  }
}

.notesList {
  list-style: none;
  > li {
    position: relative;
    margin-bottom: 24px;
  }
  > li:not(:first-child)::before {
    content: '';
    height: 1px;
    background-color: $dark-grey;
    opacity: 0.2;
    position: absolute;
    width: 100%;
  }
}

.noteHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 28px 0 8px;
  .authorName {
    color: $turquoise;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .noteDate {
    color: $dark-grey;
    font-size: 0.875rem;
    align-self: flex-end;
  }
}

.noteText {
  color: $almost-black;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .noteTitle {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-bottom: 6px;
  }
}

.noteTextCompose {
  width: 80vw;
  line-height: 1.5rem;

  blockquote {
    font-size: 18px;
    padding: 1rem 2rem;
    border-left: 7px solid #78c0a8;
    line-height: 30px;
    background: #ededed;
    margin: 0;
  }

  blockquote::before {
    content: '\201C';
    color: #78c0a8;
    font-size: 4em;
    position: absolute;
    left: 3rem;
  }
}

.noteActions {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
}

.noteBody {
  display: flex;
}

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 899px) { @content; }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}

@mixin scrollbar {
	&::-webkit-scrollbar {
	  width: 8px;
	}

	&::-webkit-scrollbar-track {
		background-color: #EAEAEA;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #DEDEDE;
	}
}

@mixin placeholder {
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  	@content;
	}
	::-moz-placeholder { /* Firefox 19+ */
  	@content;
	}
	:-ms-input-placeholder { /* IE 10+ */
  	@content;
	}
	:-moz-placeholder { /* Firefox 18- */
  	@content;
	}
}

/*
** Brand colors
*/
$turquoise: #2EC4B6;
$orange: #FF9F1C;
$blue: #0B4F6C;
$almost-black: #3D3D3D;
$dark-grey: #757575;
$light-grey: #C8CDE1;
$white: #FFFFFF;

$blue-primary: #456990;
$blue-navButton: #224F6D;
$blue-primary-dark: #00354c;
$blue-mobile-click-state: #1B3F57;
$light-turquoise: #c6edeb;
$lighter-turquoise: #e2f6f5;
$grey: #b8c1cb;
$border: #dedede;

/*
** Feedback colors
*/
$error-red: #FF5353;
$info-blue: #bed1d8;
$success-green: #a8e9a8;

$spacing-standard: 8px;
$spacing-double: $spacing-standard * 2;
$spacing-triple: $spacing-standard * 3;

$spacing-md: $spacing-double;
$spacing-lg: $spacing-double * 2;
$spacing-xl: $spacing-triple * 2;

$inboxSidebarWidth: 330px;
$navbarHeight: 64px;
$layoutMinHeight: calc(100vh - $navbarHeight);


/**
 * Bezier curve
 */

$curve: cubic-bezier(0.4, 0.3, 0.2, 1);


/**
 * Card shadow
 */

$cardShadow: 1px 1px 3px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(0,0,0,0.05);

// the :export directive is the magic sauce for webpack
:export {
  turquoise: $turquoise;
  orange: $orange;
  blue: $blue;
  almost: $almost-black;
  darkGrey: $dark-grey;
  lightGrey: $light-grey;
  white: $white;
  blue-primary: $blue-primary;
  blue-navButton: $blue-navButton;
  blue-primary-dark: $blue-primary-dark;
  blue-mobile-click-state: $blue-mobile-click-state;
  light-turquoise: $light-turquoise;
  lighter-turquoise: $lighter-turquoise;
  grey: $grey;
  border: $border;
}

@import '~styles/variables';
@import '~styles/mixins';

.root {
  header {
    display: flex;
    margin: 16px 0;

    @include mediaQuery(max, 600px) {
      flex-direction: column;
    }

    > div {
      padding: 8px 24px;
      font-size: 1rem;
      cursor: pointer;
      white-space: nowrap;

      @include mediaQuery(max, 600px) {
        flex: 0 0 35%;
        margin-bottom: 10px;
        max-width: 35%;
        padding-left: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      @include mediaQuery(max, 425px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      &.active {
        border-bottom: 1px solid $turquoise;
        color: $turquoise;
      }
    }
  }
  .listHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 12px;

    .itemHeader {
      flex-basis: 75%;
      text-align: left;
    }
    .completedHeader {
      flex-basis: 25%;
      text-align: left;
    }
  }
}

.itemList {
  list-style-type: none;
  padding-left: 2px; // room for box-shadow

  .Link {
    display: flex;
    width: 100%;
    text-decoration: none;
    justify-content: space-between;
    color: inherit;
  }

  li {
    font-size: 0.85em;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    height: 40px;
    box-shadow: $cardShadow;
    border-radius: 3px;
    background-color: #FFF;
    cursor: pointer;
    margin: 0 2px;

    &.itemNotAvailable {
      cursor: text;
    }
    .title {
      flex-basis: 80%;
      text-align: left;
    }
    .completed {
      display: flex;
      align-items: center;
      flex-basis: 20%;
      text-align: left;
    }
  }

  li + li {
    margin-bottom: 8px;
    margin-top: 8px;
  }


}

.TruncatedText > div
  display: inline

.TruncatedText > div > p:last-child
  line-height: 22px
  display: inline
  text-align: justify
  display: inline


.ExpandTextButton
  margin: 0 0.25em
  font-size: 1.1em
  min-width: auto
  min-height: auto

@value darkGrey from '~styles/colors.sss'

.NotesModal
  max-height: 400px

.NotesModalContent
  min-height: 300px
  min-width: 400px

.Notes li:not(:first-child)::before
  content: ''
  height: 1px
  background-color: darkGrey
  opacity: 0.2
  position: absolute
  width: 100%

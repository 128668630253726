@import '~styles/variables';

.dashboardRoot {
  display: grid;
  grid-template: 1fr / 24px 2fr 1fr;
  @include for-tablet-landscape-up {
    grid-template: 1fr / 48px 2fr 1fr;
    grid-template-columns: 48px minmax(auto, auto) 48px;
  }

  .dashboardGreeting {
    color: $almost-black;
    font-size: 2rem;
    line-height: 2rem;
    padding: $spacing-triple 0;
  }
}

.dashboardContentContainer {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: auto;
  grid-column-start: 2;
  @include scrollbar;
}

.dashboardRow {
  width: 100%;
  padding: 0 40px 32px 0;
  display: grid;
  grid-template: 1fr;
}

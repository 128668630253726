@value grey01, turquoise01 from '~styles/colors.sss'

.ConfirmRegimenModal
  padding: 0 2em

.ConfirmRegimenModal p
  margin: 1em 0
  font-weight: 500

.Title
  font-size: 24px
  font-weight: 500
  margin: 1em 0

.TitleText
  color: grey01
  font-size: 18px

.Subtitle
  color: turquoise01
  font-size: 18px
  font-weight: 500
  margin: 1em 0

.SubtitleText
  font-size: 20px

.Footer
  display: flex
  margin-top: 4rem
  justify-content: center

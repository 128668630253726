@value turquoise01 from '~styles/colors.sss'

.Title
  color: turquoise01
  font-size: 21px
  margin: 1em 0
  font-weight: 300

.Selection
  margin: 1em 0

.Selection p
  font-weight: 600
  font-size: 18px

.TreatmentAreas
  padding-left: 1em

.Actions
  display: flex
  align-items: center
  flex-direction: column
  margin-top: 4em

.MainActions
  display: flex
  flex-direction: row
  justify-content: space-evenly
  width: 100%

.Action
  min-width: 140px
  margin: 1em 0

.CancelAction
  position: absolute
  bottom: -80px
  margin: 0 auto

.HappyFoxChat
  position: fixed
  width: 14px
  height: 14px
  opacity: 0.2
  top: 14%
  font-size: 20px
  cursor: pointer
  border: none
  left: 70%
  background: 0 0
}

.HappyFoxChat:hover
  opacity: 0.9

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
.forDesktopUp
  .HappyFoxChat
    left: 50%
    margin-left: 324px
    margin-top: 20px

@media(max-width: 768px)
  .HappyFoxChat
    right: 30px
    left: auto

@value white01, black01, turquoise01, lightGrey from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.Details
  display: flex
  flex-shrink: 0

.Avatar
  display: none
  border: 1px solid turquoise01

.Recipient
  display: flex

.Circle
  display: flex
  padding: 6px
  background-color: lighterTurquoise
  color: turquoise01
  border-radius: 45%

.PlanName
  margin-bottom: 0.2em

.RecipientInfo
  color: black01
  display: flex
  flex-direction: column
  justify-content: space-between
  flex: 1 0
  margin-left: 1em

.RecipientInfo .RecipientName
  font-size: 18px

.RecipientInfo a
  color: turquoise01
  font-size: 12px
  text-decoration: none

.HelperText
  font-size: 12px

.BalanceInfo
  display: flex
  justify-content: flex-end
  align-items: center
  font-size: 14px

.BalanceInfo .MessagesNumber
  display: flex
  align-items: center
  justify-content: center
  margin-right: 0.5em
  background-color: lightGrey
  border-radius: 25%
  width: 22px
  height: 22px

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .Avatar
    display: flex
    background-color: white01

  .RecipientInfo .RecipientName
    font-size: 32px

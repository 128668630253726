@import '~styles/variables';

.listContainer {
  color: $almost-black;
}

.listContainer header {
  font-size: 0.75em;
  margin-bottom: 12px;
  font-weight: 600;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
}

.listContainer ul {
  list-style-type: none;
}

.LinkItem {
  color: $almost-black;
  text-decoration: none;
}

.listContainer ul > li{
  font-size: 0.85em;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  box-shadow: $cardShadow;
  border-radius: 3px;
  background-color: #FFF;
  cursor: pointer;
  margin-top: 8px;

}

.listContainer ul > a {
  padding: 0 12px;
  display: flex;
  height: 54px;
  box-shadow: $cardShadow;
  border-radius: 3px;
  background-color: #FFF;
  cursor: pointer;
  margin-top: 8px;

  li {
    font-size: 0.85em;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

}

@import 'variables';


/**
 * Global styles for surveys
 */

:global(.survey-question-text) {
  color: $almost-black;
  font-weight: 600;
  font-size: 1rem;
}


:global(.question-row-text) {
  font-size: 0.75rem;
  color: $dark-grey;
  display: block;
  margin-bottom: 12px;
}

:global(.invalid-input) {
  p, span, label, div>label {
    color: $error-red;
  }
  div>input, textarea {
    border-color: $error-red;
  }
  span {
    font-size: 0.75rem;
    padding: 4px;
  }
}

:global(.validation-error) {
  color: $error-red;
  font-size: 0.75rem;
  padding: 4px;
}

@value white01, darkGrey, turquoise01, lightTurquoise, lighterTurquoise from '~styles/colors.sss'
@value forTabletPortraitUp from '~styles/media.sss'

.Notification
  display: flex
  align-items: flex-start
  padding: 12px 18px
  cursor: pointer
  color: darkGrey
  position: relative
  user-select: none

.Notification::after
  content: ''
  height: 2px
  background-color: #EEE
  box-sizing: border-box
  position: absolute
  top: -1px
  left: 18px
  right: 18px
  transition: opacity 0.15s ease-out

.Notification:hover
  background-color: lighterTurquoise

.Content
  display: flex
  flex-direction: column

.Title
  display: flex
  flex-direction: column
  margin-bottom: 12px
  font-weight: 600

.Date
  color: darkGrey
  font-size: 12px
  margin-top: 0.5em

.Body
  font-size: 0.75rem
  line-height: 1.125rem

.Actions
  display: flex
  flex: 1 0 45px
  justify-content: flex-end

.Actions > *
  margin: 0 5px

.MarkAsRead
  border-radius: 50%
  background-color: turquoise01
  width: 16px
  height: 16px

.MarkAsUnread
  border-radius: 50%
  background-color: lightTurquoise
  width: 16px
  height: 16px

/* stylelint-disable-next-line selector-type-case, selector-type-no-unknown */
forTabletPortraitUp
  .Remove
    display: none

  .MarkAsUnread
    display: none

  .Notification:hover .MarkAsUnread
    display: inline-block

  .Notification:hover .Remove
    display: inline-block

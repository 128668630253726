@value border01 from './colors.sss'

/**
 * Bezier curve
 */
@value curve: cubic-bezier(0.4, 0.3, 0.2, 1)

.Scrollbar::-webkit-scrollbar
  width: 8px

.Scrollbar::-webkit-scrollbar-track
  background-color: #EAEAEA

.Scrollbar::-webkit-scrollbar-thumb
  background-color: border01

.InvisibleScrollbar::-webkit-scrollbar
  display: none

.CreatePasswordForm
  margin: 2em auto
  max-width: 600px
  padding: 0 1em

.Title
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  margin: 2em 0
  text-align: center

.SectionTitle
  margin: 1.5em 0
  text-align: center

.FormTitle
  font-family: 'Open Sans', sans-serif
  font-weight: normal
  font-size: 16px
  margin: 1em 0

.VerticalGroup
  display: flex
  flex-direction: column
  margin: 0.8em 0

.InputGroup
  display: flex
  flex-direction: column
  flex: 1

.Label
  font-size: 14px
  margin: 0.5em 0

.Footer
  text-align: center
  margin: 4em 0

@value white01, turquoise01 from '~styles/colors.sss'
@value forPhoneOnly from '~styles/media.sss'

.Modal
  display: flex
  align-items: center
  justify-content: center

.Form
  background-color: white01
  box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.05), -2px -1px 5px 0 rgba(0, 0, 0, 0.1), -2px -3px 1px -2px rgba(0, 0, 0, 0.06), -2px -2px 2px 0 rgba(0, 0, 0, 0.07)
  max-width: 480px
  padding: 2em

.FormTitle
  font-family: 'Open Sans', sans-serif
  color: turquoise01
  font-size: 36px
  font-weight: bold
  margin: 1em 0

.VerticalGroup
  display: flex
  flex-direction: column
  margin: 0.8em 0

.InputGroup
  display: flex
  flex-direction: column
  flex: 1

.Label
  font-size: 14px
  margin: 0.5em 0

.Footer
  display: flex
  justify-content: flex-end
  margin: 2em 0

forPhoneOnly
  .Form
    padding: 1em

  .FormTitle
    font-size: 24px

  .Footer
    flex-direction: column
    height: 150px
    justify-content: space-around
